/* eslint-disable @typescript-eslint/no-unused-vars */
import { postReq } from "../modules/apiConsume"
import useAIPlanOutlookPrompts from "../modules/useAIPlanOutlookPrompts";
import CryptoJS from 'crypto-js';

const useRSPlanWeekOutlookAI = () => {

    const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

    const { defaultSystemPrompt, buildPromptWeekOutlookCurrentSummary, buildPromptsAllWeeklyOutlook } = useAIPlanOutlookPrompts()


    const defaultPromptCall = async (prompt: any, gptModel?: string) => {

        const message = [{
            "role": "system",
            "content": defaultSystemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]

        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: gptModel ? gptModel : 'gpt-4o-2024-08-06', gptTemp: '0' })
        if (response && response.status === "error") {
            alert(response.error.error.error.message)
        }

        return response?.data?.output || []
    }


    const fetchResponseWeekOutlook = async (userPlanInfo: any, weekDataCurrent: any, prompt: string, editedPrompt?: any, gptModel?: string) => {

        const filledPrompt = buildPromptsAllWeeklyOutlook(userPlanInfo, weekDataCurrent, prompt)
        // console.log('>>> prompt: ', prompt)
        const response = await defaultPromptCall(editedPrompt != '' ? editedPrompt : filledPrompt, gptModel)
        const responseMsg = response.message && typeof (response.message) !== "string" ? response.message : response.message && typeof (response.message) === "string" ? { headline: response.message, recap: "" } : response.headline ? { headline: response.headline, recap: response.recap } : response || 'No messages found'
        return responseMsg
    }

    return { fetchResponseWeekOutlook }
}

export default useRSPlanWeekOutlookAI