/* eslint-disable @typescript-eslint/no-unused-vars */
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BillingUpdateMessage from '../misc/BillingUpdateMessage'
import ChooseYourPlanForm from './ChooseYourPlanForm'
import {
	getCurrentAmount,
	getCurrentPlanCode,
	getPlanArgs
} from '../../modules/subscriptionUtils'
import { getPlanCosts, getSubscriptionInfo } from '../../modules/accountActions'
import { DEFAULT_COSTS } from '../../modules/values'
import { FC, useEffect, useState } from 'react'
import useStore from '../../store/useStore'
// import { ICurrentUser } from '../../store/createAccountStore'
import { HAS_SALE } from '../../modules/cliEnvValues'
import { IComponentStore } from '../../store/createComponentStore'
import useQueryParams from '../../hooks/useQueryParams'
import { useLocation } from 'react-router-dom'
import useSale from '../../hooks/useSale'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Props
export type SubscriptionFormProps = {
	isExpiring: boolean
	showInvalidBillingMessage?: boolean
	setParentCosts: (x: any) => any
	setMonthly: (x: boolean) => any
	setCurrentPlanId: (x: string) => any
	setHasPendingId: (x: boolean) => any
	setIsActive: (x: boolean) => any
	setInfo: (x: any) => any,
	isAccount?: boolean,
	side?: boolean,
	userInfo?: any
}

// Detail Line Props
type DetailLineProps = {
	title: string
	details?: string
}

// Detail Line
export const DetailLine: FC<DetailLineProps> = ({ title, details }) => (
	<div className="mb-4">
		<div>
			<h5 className="mb-0 text-capitalize">{title}</h5>
		</div>
		{details && <div>{details}</div>}
	</div>
)

// Default Details
const DEFAULT_DETAILS = {
	'Current Plan': 'N/A',
	Status: 'N/A',
	'Expires On': 'N/A',
	Rate: 'N/A',
	'Next Billing Cycle': 'N/A',
}

/**
 * SubscriptionForm Component
 */

const SubscriptionForm: FC<SubscriptionFormProps> = ({
	isExpiring,
	showInvalidBillingMessage = false,
	setParentCosts,
	setMonthly,
	setCurrentPlanId,
	setHasPendingId,
	setIsActive,
	setInfo,
	isAccount,
	side,
	userInfo
}) => {
	// State

	const deviceRequestKey = localStorage.getItem('deviceRequestKey') || undefined

	const { activeSale, isLoadingSale } = useSale()
	const { mobileType } = useDeviceDetect()

	// const hasSale = HAS_SALE || activeSale && !deviceRequestKey ? true : false
	const hasSale = HAS_SALE || activeSale && !deviceRequestKey || activeSale && deviceRequestKey && mobileType === "Ios" ? true : false

	const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
	const [details, setDetails] = useState({ ...DEFAULT_DETAILS } as any)
	const [isInAppPayment, setIsInAppPayment] = useState(false)
	const [hasMonthlyPlan, setHasMonthlyPlan] = useState(true)

	const { setIsLoading, setLoadingMsg } = useStore((state: IComponentStore) => state)

	// const { hasOffer } = useStore((state: ICurrentUser) => state)
	const { offer } = useQueryParams()
	const url = useLocation();


	const hasOffer = Number(offer) == 2 ? true : false

	// Get Data
	const getData = async () => {
		if (hasSale) {
			setLoadingMsg('Please wait')
			setIsLoading(true)
		}

		const info: any = {}
		const newDetails = { ...DEFAULT_DETAILS } as any
		const { isActive, subscription } = await getSubscriptionInfo()
		const planCode = getCurrentPlanCode(subscription)
		const hasPendingId = subscription?.change_id ? true : false
		const isCanceled = subscription?.canceled

		// alert(isCanceled)

		newDetails.Status = isActive && !isCanceled ? 'Active' : isActive && isCanceled ? 'Canceled' : 'Expired'
		const { hasSale: hasSalePlan } = getPlanArgs(planCode)
		const newCosts = await getPlanCosts({
			hasOffer: hasOffer,
			isRenew: false,
			isYearlyRenewOnly: false,
			hasSale: hasSale ? true : hasSalePlan,
		})

		if (hasSale) {
			setLoadingMsg(undefined)
			setIsLoading(false)
		}

		if (subscription) {
			subscription.id.includes('GPA') ? setIsInAppPayment(true) : setIsInAppPayment(false)
			const planId = subscription.id
			const isMonthly = planCode.includes('month')
			const isYearly = planCode.includes('annual')
			const rate = userInfo?.account_id == 25299 ? 15 : getCurrentAmount(subscription)
			const nextBillingDate = isActive ? new Date(subscription.next_billing).toLocaleDateString() : 'N/A'
			const endDate = isActive || isExpiring ? new Date(subscription.end_date).toLocaleDateString() : 'N/A'
			newDetails['Current Plan'] = isActive ? isMonthly ? 'Monthly' : isYearly ? 'Yearly' : planCode.toUpperCase() : 'N / A'
			newDetails.Rate = isMonthly ? `$${rate} USD/month` : isYearly ? `$${rate} USD/year` : `$${rate} USD`
			if (isCanceled || isExpiring || !isMonthly && !isYearly) delete newDetails['Next Billing Cycle']
			else
				newDetails['Next Billing Cycle'] = nextBillingDate

			info.amount = rate
			info.nextDate = nextBillingDate
			if (isActive && !isExpiring && !isCanceled && (isMonthly || isYearly)) delete newDetails['Expires On']
			else newDetails['Expires On'] = endDate
			setCurrentPlanId(planId)
			setHasPendingId(hasPendingId)
			setHasMonthlyPlan(isMonthly)
		}
		setIsActive(isActive)
		setDetails(newDetails)
		setInfo(info)
		setCosts(newCosts)
		setParentCosts(newCosts)
	}

	// useEffect(() => {
	// 	if (hasSale && costs.annual == DEFAULT_COSTS.annual) {
	// 		getData()
	// 	}
	// }, [costs, hasSale])

	// Mount Effect
	useEffect(() => {
		if (userInfo.account_id)
			getData()
	}, [userInfo])


	// Mount Effect
	useEffect(() => {
		if (activeSale && !isLoadingSale)
			getData()
	}, [activeSale, isLoadingSale])

	// Render
	return (
		<div className="subscription-form px-form mx-1 mt-n4">
			{/* {showInvalidBillingMessage && (
				<Row className="mb-5">
					<Col>
						<BillingUpdateMessage hasStyledText isBlack />
					</Col>
				</Row>
			)} */}
			<Row>
				{isAccount ? (
					<>
						{!side && <h3 className='mt-4 mb-3' style={{ letterSpacing: 0 }}><b>Subscription</b></h3>}
						<Col xs={12} md={12} className="mt-2 mb-2">
							{Object.keys(details).map((key, index) => (
								<Row className='mb-n2' key={index}>
									<Col>
										<DetailLine
											title={key}
											details={details[key as keyof typeof details]}
										/>
									</Col>
								</Row>
							))}
						</Col>
						<Col xs={12} md={12} className={`mb-1 ml-3`}>
							{/* <DetailLine title={'Available Plans'} /> */}
							<h5 className='text-capitalize'><b>Available Plans</b></h5>
							<ChooseYourPlanForm
								hidePlanDetails
								hasMonthlyPlan={hasMonthlyPlan}
								setMonthly={setMonthly}
								monthCost={costs.monthly}
								yearCost={costs.annual}
								isAccount={isAccount}
							/>
						</Col>
					</>
				) : (
					<>
						{/* <Col xs={1} md={4} className="mb-4 ml-3">

						</Col> */}
						<Col xs={12} md={12} className={`mb-4 ml-3`}>
							<h3 title={'Available Plans'} />
							<ChooseYourPlanForm
								hidePlanDetails
								hasMonthlyPlan={hasMonthlyPlan}
								setMonthly={setMonthly}
								monthCost={costs.monthly}
								yearCost={costs.annual}
								isAccount={isAccount}
							/>
						</Col>
					</>
				)}

			</Row>
		</div>
	)
}

export default SubscriptionForm
