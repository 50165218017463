/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useRSPlanWeekOutlookAI from '../../../hooks/useRSPlanWeekOutlookAI'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { GetTrainingPlanInfo } from '../../../modules/aiTrainingPlanActions'
import { getReq } from '../../../modules/apiConsume'
import { GetAppleHealtActivity } from '../../../modules/appleHealthKitActions'
import { getCorosUserByUserId, getUserWorkoutsByRange } from '../../../modules/corosActions'
import { GetUserCustomPace } from '../../../modules/trainingPlanActions'
import useAIPlanOutlookPrompts from '../../../modules/useAIPlanOutlookPrompts'
import { CreateWeeklyOutlookDetails, GetPromptWeeklyOutlook, GetWeeklyOutlookDetails } from '../../../modules/weeklyOutlookActions'
import useTrainingTools from '../../../pages/admin/useTrainingTools'
import useGPTTrainingPlan from '../../../pages/race/useGPTTrainingPlan'
import { CorosWorkout } from '../coros/coros.types'
import { WeeklyOutlook } from './weekly-outlook.types'

const useWeeklyOutlook = (userInfo: any) => {
    const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()
    const { ConvertM2H2, getGarminPace, ConvertMetersToMiles } = useTrainingPlanUtils()
    const { buildPaceInitial, convertToMinPerMile, convertMinutesToMinutesAndSeconds } = useTrainingTools({ userInfo })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [weekDataSelected, setWeekDataSelected] = useState<any>({ current: {}, upcoming: {} })
    const [planCustomPaces, setPlanCustomPaces] = useState<any>([])
    const { buildPromptWeekOutlookCurrentSummary, buildPromptsAllWeeklyOutlook, getPromptWeeklyOutlookDefault } = useAIPlanOutlookPrompts()
    const { fetchResponseWeekOutlook } = useRSPlanWeekOutlookAI()

    const sanitizedText = (text: string) => {
        return text.replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/\t/g, "\\t")
    }

    const getGarminUserDetails = async (userId: number) => {
        const response = await getReq(`/v2/garmin/user/id?userId=${userId}`)
        const data = response.data.result[0]
        return data
    }

    const getCorosUserDetails = async (userId: number) => {
        const response = await getCorosUserByUserId(userId)
        const data = response.data.result[0]
        return data
    }

    const getAppleHealthUserDetails = async (userId: number) => {
        const response = await GetAppleHealtActivity(userInfo.internal_id, '')
        if (response?.data?.result?.length > 0)
            return true
    }

    const getPrompts = async () => {
        const current = await GetPromptWeeklyOutlook(1)
        const upcoming = await GetPromptWeeklyOutlook(2)

        return { current: current?.data?.result[0]?.prompt, upcoming: upcoming?.data?.result[0]?.prompt }
    }

    const getWeeklyOutputAIResponse = async (dataRace: any, promptDefault: string) => {
        const selectedGPTModel = "gpt-4o-2024-08-06"
        const result = await fetchResponseWeekOutlook(dataRace, weekDataSelected, promptDefault, '', selectedGPTModel)
        return result
    }

    const parseAIresponse = (response: any) => {
        const headline = response?.headline || ""
        const recap = response?.recap || ""

        if (headline && recap) {
            const parsedResponse: string = headline + '\n' + recap as string
            return parsedResponse
        }
        return ''
    }

    const getWeeklyOutlookDetails = async (allTrainingData: any, weekNumSelected: number) => {

        setIsLoading(false)

        const planDedatails = {
            planType: allTrainingData?.race_type.toLowerCase() === 'half' ? 'Half Marathon' : allTrainingData?.race_type.toLowerCase() === "full" ? 'Full Marathon' : allTrainingData?.race_type.toLowerCase(),
            planName: allTrainingData?.event_name,
            currentWeek: weekNumSelected,
            totalWeeks: allTrainingData?.weeks,
            goal: ConvertM2H2(allTrainingData?.target_time),
            trainingPeak: allTrainingData?.max_distance,
            unit: allTrainingData?.is_km ? 'Kilometers' : 'Miles',
        }

        const mergedData = { ...allTrainingData, ...planDedatails }
        return mergedData
    }

    const createWeeklyOutlookDetails = async (userId: number, planId: number, weekNumSelected: number, weekDateStart: string, weekOutlook: string, upcomingOutlook: string) => {
        // const weekDate = `"${moment(weekDateStart).format('YYYY-MM-DD')}-${moment(weekDateStart).add(1, 'week').format('YYYY-MM-DD')}"`
        const newData: WeeklyOutlook = {
            user_id: userId,
            plan_id: planId,
            week_number: weekNumSelected,
            week_date: weekDateStart,
            week_outlook: weekOutlook,
            upcoming_outlook: upcomingOutlook,
            feedback_message: "",
            feedback_rate: 0
        }

        const response = await CreateWeeklyOutlookDetails(newData)
        return response
    }

    const getCurrentWeeklyOutlook = async (userId: number, planId: number, weekNumSelected: number, weekDateStart: string) => {
        const currentWeeklyOutlook = await GetWeeklyOutlookDetails(userId, planId, weekNumSelected)
        const currentWeeklyOutlookData = currentWeeklyOutlook?.data?.result[0]
        console.log('>>> currentWeeklyOutlookData: ', currentWeeklyOutlookData)
        return currentWeeklyOutlookData
    }

    const getWeeklyOutlook = async (userId: number, weekNumSelected: number, isKm: boolean, dataRace: any, trainingPlanInfo: any) => {
        let weeklyOutlook: any = {}

        const weekDataSelected = dataRace?.training?.filter((x: any) => x.week == weekNumSelected) && dataRace?.training?.filter((x: any) => x.week == weekNumSelected)[0]
        const weekDataUpcoming = dataRace?.training?.filter((x: any) => x.week == weekNumSelected + 1) && dataRace?.training?.filter((x: any) => x.week == weekNumSelected + 1)[0]
        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        let currentWeeklyOutlook = await getCurrentWeeklyOutlook(userId, dataRace?.plan_id, weekNumSelected, weekDateStart)

        const corosData = await getCorosData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo)
        const garminData = await getGarminData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo)
        const appleHealthData = await getAppleHealthKitData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo)
        weeklyOutlook = { coros: corosData, garmin: garminData, appleHealth: appleHealthData }

        const results = {
            current: weeklyOutlook?.coros?.current || weeklyOutlook?.garmin?.current || weeklyOutlook?.appleHealth?.current,
            upcoming: weeklyOutlook?.coros?.upcoming || weeklyOutlook?.garmin?.upcoming || weeklyOutlook?.appleHealth?.upcoming
        }

				let currentGraphData = []
				const currentDailyRuns = results?.current?.daily_runs
				if (currentDailyRuns) {
					const dailyRunsArr = JSON.parse(currentDailyRuns)
					currentGraphData = dailyRunsArr.map((x: any, xi: number) => {

						const paceArr = x?.planned_pace?.replaceAll(' /mile', '').replaceAll(' /km', '')?.replaceAll(' hr', 'h')?.replaceAll(' min', 'm')?.replaceAll(' sec', 's')?.split(' ')
						let plannedPace = 0
						for (const pc of paceArr) {
							if (pc.includes('h')) {
								plannedPace += (Number(pc.replaceAll('h', '')) || 0) * 60
							}
							if (pc.includes('m')) {
								plannedPace += (Number(pc.replaceAll('m', '')) || 0)
							}
							if (pc.includes('s')) {
								plannedPace += (Number(pc.replaceAll('s', '')) || 0) / 60
							}
						}
						console.log('>>> plannedPace: ', plannedPace)
						return {
							day: x.day_name,
							planned_distance: x.planned_distance && x.planned_distance !== 'NA' && x.planned_distance != 0 ? x.planned_distance : 0,
							completed_distance: x.completed_distance && x.completed_distance !== 'NA' && x.completed_distance != 0 ? x.completed_distance : 0,
							planned_pace: 0
						}
					})
					console.log('dailyRunsArr', dailyRunsArr)
				}

				//let upcomingGraphData = {}
				const upcomingDailyRuns = results?.upcoming?.daily_runs
				let upcomingDistance = 0
				if (upcomingDailyRuns) {
					const upcomingRunsArr = JSON.parse(upcomingDailyRuns)
					for(const u of upcomingRunsArr) {
						upcomingDistance += u.run_distance && u.run_distance !== 'NA' && u.run_distance != 0 ? u.run_distance : 0
					}
				}

				console.log('>>> results: ', results)

				const graphData = { currentGraphData, upcomingDistance, isKm }

        if (!currentWeeklyOutlook) {
            const prompts = await getPrompts()
            const currentFilledPrompt = buildPromptsAllWeeklyOutlook(dataRace, results, prompts.current, weekNumSelected, '')
            const currentResult = await getWeeklyOutputAIResponse(dataRace, currentFilledPrompt)
            const currentResponseText = parseAIresponse(currentResult)

            const upcomingFilledPrompt = buildPromptsAllWeeklyOutlook(dataRace, results, prompts.upcoming, weekNumSelected + 1, currentResponseText)
            const upcomingResult = await getWeeklyOutputAIResponse(dataRace, upcomingFilledPrompt)

            await createWeeklyOutlookDetails(userId, dataRace?.plan_id, weekNumSelected, weekDateStart, currentResult, upcomingResult)
            currentWeeklyOutlook = await getCurrentWeeklyOutlook(userId, dataRace?.plan_id, weekNumSelected, weekDateStart)

            const sanitizedCurrent = sanitizedText(currentWeeklyOutlook.week_outlook)
            const sanitizedUpcoming = sanitizedText(currentWeeklyOutlook.upcoming_outlook)

            return {
                current: JSON.parse(sanitizedCurrent), upcoming: JSON.parse(sanitizedUpcoming), details: currentWeeklyOutlook, ...graphData
            }
        }
        else {
            const sanitizedCurrent = sanitizedText(currentWeeklyOutlook.week_outlook)
            const sanitizedUpcoming = sanitizedText(currentWeeklyOutlook.upcoming_outlook)
            return {
                current: JSON.parse(sanitizedCurrent), upcoming: JSON.parse(sanitizedUpcoming), details: currentWeeklyOutlook, ...graphData
            }
        }
    }

    const getCorosData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any) => {
        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        const corosData = await getUserWorkoutsByRange(userId, weekDateStart, moment(weekDateStart).add(1, 'week').format('YYYY-MM-DD'))

        const dailyRunsNewCurrent: any[] = []

        const dailyRuns = JSON.parse(weekDataSelected?.daily_runs)
        dailyRuns.forEach((x: any, i: any) => {
            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const corosDataOnDate = corosData?.find((x: any) => x.activity_date == thisDate)
            const marathonPaces = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'

            let activities = corosDataOnDate?.activities && corosDataOnDate?.activities?.length ? JSON.parse(corosDataOnDate?.activities) as CorosWorkout[] : []
            //get only running activities
            activities = activities.filter(x => x.mode == 8)

            let totalDistance: any = 0
            let totalAvgSpeed = 0
            let totalAvgHeartRate = 0
            let totalDuration = 0

            //sum up all runnning activities
            activities?.forEach(activity => {
                totalDistance += activity.distance
                totalAvgSpeed += activity.avgSpeed
                totalAvgHeartRate += activity.avgHeartRate ? activity.avgHeartRate : 0
                totalDuration += activity.duration
            })

            totalAvgSpeed = totalAvgSpeed / activities?.length
            totalAvgHeartRate = Number((totalAvgHeartRate / activities?.length).toFixed(0))

            const completedPace = totalDistance / totalDuration
            const paceDuration = completedPace ? getGarminPace(isKm ? true : false, completedPace) : 'NA'
            const planned_pace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId)?.paceSpeed)) + ' /mile' || 'NA'
            const convertedDistance = totalDistance ? (!isKm ? ConvertMetersToMiles(totalDistance as number)?.toFixed(1) : Number(totalDistance as number / 1000)?.toFixed(1)) : ''

            if (dailyRuns[i]) {
                dailyRunsNewCurrent.push({
                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": convertedDistance ? convertedDistance : 0,
                    "run_type": dailyRuns[i]?.run_type,
                    "cross_training": dailyRuns[i]?.cross_training,
                    "heartRate": totalAvgHeartRate || 'NA',
                    "planned_pace": planned_pace,
                    "completed_pace": paceDuration || 'NA',
                })

                // !configRunDataParams.xt && delete dailyRunsNewCurrent[i].cross_training
            }
        })


        weekDataSelected.daily_runs = JSON.stringify(dailyRunsNewCurrent)
        // corosData && corosData.length && setWeekDataSelected({ current: weekDataSelected, upcoming: weekDataUpcoming })
        return { current: weekDataSelected, upcoming: weekDataUpcoming }
    }

    const getGarminData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any) => {
        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        let hasGarminData = false

        const userDetails = await getGarminUserDetails(userId)
        const garminID = userDetails?.garmin_id

        const currentActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${weekDateStart}&activity_date_to=${moment(weekDateStart).endOf('week').format('YYYY-MM-DD')}`)

        const pastActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${moment().startOf('week').format('YYYY-MM-DD')}&activity_date_to=${moment().endOf('week').format('YYYY-MM-DD')}`)

        const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []

        const dailyRunsNewCurrent: any[] = []
        let mergedData: any[] = []

        const currentActivityData = currentActivity.data.result
        hasGarminData = currentActivityData.length > 0

        let hasActivityFiltered = false

        dailyRuns.forEach((x: any, i: any) => {

            const garminDataRunning = currentActivityData.filter((x: any) => x.activity_details?.summary?.activityType?.toLowerCase().includes('run') || x.activity_details?.summary?.activityType?.toLowerCase().includes('walk') || x.activity_details?.summary?.activityName?.toLowerCase().includes('run') || x.activity_details?.summary?.activityName?.toLowerCase().includes('walk'))

            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            console.log('>>> thisDate: ', thisDate)

            const allActivities = pastActivity.data.result
            // incase fetching data from the selected week yielded no results, we try getting from all activities data
            const activityFallbackFilter = !hasGarminData && allActivities && allActivities.find((x: any) => x.activity_date == thisDate)
            console.log('>>> activityfallbackFilter: ', activityFallbackFilter)

            const garminDataOnDate = garminDataRunning && garminDataRunning?.find((x: any) => x.activity_date == thisDate) ? garminDataRunning?.find((x: any) => x.activity_date == thisDate) : activityFallbackFilter
            // const garminDataMultipleOnDate = currentActivityData?.filter((x: any) => x.activity_date == thisDate)
            const garminDataMultipleOnDate = garminDataRunning?.filter((x: any) => x.activity_date == thisDate)

            if (activityFallbackFilter && activityFallbackFilter != '') hasActivityFiltered = true
            activityFallbackFilter && console.log('!!! fallback-filter garminDataOnDate: ', garminDataOnDate)

            const hasMultipleSyncedActivity = garminDataMultipleOnDate.length > 1
            hasMultipleSyncedActivity && console.log('>>> garminDataMultipleOnDate: ', garminDataMultipleOnDate)


            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'
            const pacesOnThisDay = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            const plannedPace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(pacesOnThisDay, plannedDistance, trainingPlanInfo, userId, planCustomPaces)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(pacesOnThisDay, plannedDistance, trainingPlanInfo, userId, planCustomPaces)?.paceSpeed)) + ' /mile' || 'NA'
            // const plannedPace = buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed

            const completedDistance = garminDataOnDate && (isKm ? garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.001 : garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.000621371)
            const completedPace = garminDataOnDate?.activity_details?.summary?.averagePaceInMinutesPerKilometer

            let dayDataUserCompleted: any = []
            let dayDataPlanDetails: any = []

            if (dailyRuns[i]) {

                dayDataPlanDetails = {

                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "planned_pace": plannedPace,

                    "run_type": dailyRuns[i]?.run_type,
                    "cross_training": dailyRuns[i]?.cross_training,
                }


                hasMultipleSyncedActivity
                    ? garminDataMultipleOnDate.forEach((x: any, i: number) => {

                        // just in case we want the keys to be numbered
                        const keyNameDistance = `${'completed_distance_' + i}`
                        const keyNamePace = `${'completed_pace_' + i}`
                        const keyNameHeartRate = `${'heartRate_' + i}`

                        const completedDistance = isKm ? x?.activity_details?.summary?.distanceInMeters * 0.001 : x?.activity_details?.summary?.distanceInMeters * 0.000621371
                        const completedPace = x?.activity_details?.summary?.averagePaceInMinutesPerKilometer

                        dayDataUserCompleted.push({
                            'completed_distance': completedDistance.toFixed(2) || x?.activity_details?.summary?.distanceInMeters,
                            'completed_pace': (isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile') || x?.activity_details?.summary?.averagePaceInMinutesPerKilometer || 'NA',
                            'heartRate': x?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                        })

                    })
                    : dayDataUserCompleted = {
                        "heartRate": garminDataOnDate?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                        "completed_distance": completedDistance ? completedDistance.toFixed(2) : '0',
                        "completed_pace": completedPace ? isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile' : 'NA',
                    }

                console.log('>>> dayDataUserCompleted: ', dayDataUserCompleted)


                let multipleRunningActivity: any = []
                if (hasMultipleSyncedActivity) multipleRunningActivity = { running_activity: dayDataUserCompleted }

                hasMultipleSyncedActivity && console.log('>>> day: ', dailyRuns[i]?.day_name)
                hasMultipleSyncedActivity && console.log('>>> multiple garminDataRunning: ', multipleRunningActivity)
                hasMultipleSyncedActivity ? mergedData = { ...dayDataPlanDetails, ...multipleRunningActivity } : mergedData = { ...dayDataPlanDetails, ...dayDataUserCompleted }

                dailyRunsNewCurrent.push(mergedData)

                // !configRunDataParams.xt && delete dailyRunsNewCurrent[i].cross_training

            }

        })

        weekDataSelected.daily_runs = JSON.stringify(dailyRunsNewCurrent)

        return { current: weekDataSelected, upcoming: weekDataUpcoming }
    }

    const getAppleHealthKitData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any) => {

        const convertMinPerMeterToMinPerMile = (minPerMeter: any) => {

            const metersPerMile = 1609.344; // Conversion factor: meters in a mile
            const paceInMinPerMile = minPerMeter * metersPerMile
            return paceInMinPerMile
        }


        const convertMetersToMiles = (meters: number) => {
            const metersPerMile = 1609.344
            const miles = meters / metersPerMile
            return miles
        }

        const convertMetersToKm = (meters: number) => {
            const metersPerKm = 1000
            const km = meters / metersPerKm
            return km
        }

        const dailyRunsNewCurrentApple: any[] = []
        const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []

        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        dailyRuns.forEach((x: any, i: any) => {

            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const currentDate = moment().format('YYYY-MM-DD')
            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'

            const marathonPaces = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            GetAppleHealtActivity(userId, thisDate).then(res => {

                const appleActivityResult = res?.data?.result?.length > 0 && res?.data?.result[0]

                appleActivityResult && console.log('!!! appleActivityResult: ', appleActivityResult)

                const appleActivityData = appleActivityResult && JSON.parse(appleActivityResult?.activity)

                // might replace || to &&
                const appleActivityRunningData = appleActivityData.length > 0 && appleActivityData.find((x: any) => x.activityName.toLowerCase().includes('running') || x.distanceInMeters > 5)

                dailyRunsNewCurrentApple.push({

                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": appleActivityRunningData.distanceInMeters ? !isKm ? convertMetersToMiles(appleActivityRunningData.distanceInMeters).toFixed(2) : convertMetersToKm(appleActivityRunningData.distanceInMeters).toFixed(2) : '0',
                    "run_type": dailyRuns[i]?.run_type,
                    "cross_training": dailyRuns[i]?.cross_training,
                    "heartRate": appleActivityRunningData.heartRate ? appleActivityRunningData.heartRate : 'NA',
                    "planned_pace": dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId)?.paceSpeed)) + ' /mile' || 'NA',
                    "completed_pace": appleActivityRunningData?.pace ? isKm ? convertMinutesToMinutesAndSeconds(appleActivityRunningData.pace * 1000) + ' /km' : convertMinutesToMinutesAndSeconds(convertMinPerMeterToMinPerMile(appleActivityRunningData.pace)) + ' /mile' : 'NA',
                })

                if (dailyRunsNewCurrentApple?.length > 5) {

                    const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

                    const sortedRuns = dailyRunsNewCurrentApple?.sort((a: any, b: any) => {
                        return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
                    })

                    console.log('>>> sortedRuns: ', sortedRuns)

                    weekDataSelected.daily_runs = JSON.stringify(sortedRuns)
                    return { current: weekDataSelected, upcoming: weekDataUpcoming }
                }

                return { current: dailyRunsNewCurrentApple, upcoming: weekDataUpcoming }

                // console.log('>>> dailyRunsNewCurrentApple: ', dailyRunsNewCurrentApple)

            }).catch(err => {
                console.log('>>> GetAppleHealtActivityData err: ', err)
            })
        })
    }

    // const checkActiveDevice = async (userId: number) => {
    //     const devices = ["garmin", "coros", "appleHealth"]
    //     const garminData = await getGarminUserDetails(userId)
    //     const corosData = await getCorosUserDetails(userId)
    //     const appleHealthData = await getAppleHealthUserDetails(userId)
    //     if (garminData && garminData?.status == 1)
    //         return devices[0]
    //     if (corosData && corosData?.status == 1)
    //         return devices[1]
    //     if (appleHealthData)
    //         return devices[2]
    //     else
    //         return false
    // }

    useEffect(() => {
        const abortController = new AbortController()
        if (userInfo && userInfo?.allTrainingData?.user_id) {
            GetUserCustomPace(userInfo?.allTrainingData?.user_id, abortController.signal).then((res: any) => {
                setPlanCustomPaces(res?.data?.result)

            }).catch(err => {
                console.log('!!! customPace err: ', err)
            })
        }

        return () => {
            abortController.abort()
        }

    }, [userInfo])

    return { weekDataSelected, getWeeklyOutlookDetails, getWeeklyOutlook }
}

export default useWeeklyOutlook
