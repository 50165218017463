/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { RSButton2 } from '../../../../components/buttons'
import { SH2 } from '../../../../components/text/RSTypography'

export type AverageRunningInfoProps = {
		done?: any,
		bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const AverageRunningInfo: FC<AverageRunningInfoProps> = ({done, bodyData, setBodyData, nextAvailability}) => {
		const [runningActivity, setRunningActivity] = useState(bodyData?.runningActivity?.index === undefined ? '' : bodyData.runningActivity.index);

    const toKM = (x: number) => (x*1.60934).toPrecision(3)
		const activities = (isKm: any) => {
			const unit = isKm ? 'km' : 'mi'
			return ['No running',
			`Less than 10 ${unit}`,
			`10 to 20 ${unit}`,
			`21 to 30 ${unit}`,
			`31 to 40 ${unit}`,
			`41 to 50 ${unit}`,
			`More than 50 ${unit}`]
		}

		useEffect(()=>{
			setBodyData({
				...bodyData,
				runningActivity: {
					index: runningActivity,
					titleKM: activities(true)[runningActivity],
					titleMI: activities(false)[runningActivity]
				}
			})
			nextAvailability(runningActivity !== '')
		},[runningActivity])

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
			<Grid container xs={12} sx={{pb: '15px'}} justifyContent='space-between' >
				<SH2 sx={{pb: 2}}>
					In the last four weeks, I average...
				</SH2>
				<Grid container xs={12} justifyContent='space-between'>
					{activities(bodyData?.isKm).map((activity: any, ai: number)=>{
						const isActive = ai === runningActivity
						return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '80px', width: '100px', mb: '15px'}} justifyContent='center' alignItems='center'>
							<RSButton2 secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setRunningActivity(ai)}>
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{activity}</Box>
								{ai>0 && <Box sx={{fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center'}}>per week</Box>}
							</RSButton2>
						</Grid>
					})}
				</Grid>
			</Grid>
		</Box>
}

export default AverageRunningInfo
