import { WeeklyOutlook } from "../api/v2/weekly-outlook/weekly-outlook.types";
import { getReq, postReq } from "./apiConsume";


export const GetTrainingPlansActiveInfo = async () =>
    await getReq(`/v2/weekly-outlook/training-plans-active`)

export const GetPromptWeeklyOutlook = async (step: number) =>
    await getReq(`/v2/weekly-outlook/prompt?step=${step}`)

export const GetAllPromptWeeklyOutlook = async () =>
    await getReq(`/v2/weekly-outlook/prompt/all`)

export const GetUsersListEmailFiltered = async (email: string) =>
    await getReq(`/v2/weekly-outlook/list-email?email=${email}`)

export const SavePromptWeeklyOutlook = async (step: number, prompt: string, notes?: string, version?: number) =>
    await postReq(`/v2/weekly-outlook/save-prompt`, { step: step, prompt: prompt, notes: notes, version: version || 0 })

export const ActivatePromptWeeklyOutlook = async (promptId: number) =>
    await postReq(`/v2/weekly-outlook/prompt/activate`, { promptId: promptId })

export const ArchivePromptWeeklyOutlook = async (promptId: number) =>
    await postReq(`/v2/weekly-outlook/prompt/archive`, { promptId: promptId })

export const RestorePromptWeeklyOutlook = async (promptId: number) =>
    await postReq(`/v2/weekly-outlook/prompt/restore`, { promptId: promptId })

export const GetWeeklyOutlookDetails = async (user_id: number, plan_id: number, week: number) =>
    await getReq(`/v2/weekly-outlook/details?user_id=${user_id}&plan_id=${plan_id}&week=${week}`)

export const CreateWeeklyOutlookDetails = async (data: WeeklyOutlook) =>
    await postReq(`/v2/weekly-outlook/details`, data)

export const CreateWeeklyOutlookFeedback = async (data: WeeklyOutlook) =>
    await postReq(`/v2/weekly-outlook/feedback`, data)
