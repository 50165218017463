import { FC, useState } from 'react'

//import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'


import { RSButton1, RSButton2 } from '../../components/buttons'
import { H1, P1, P2 } from '../../components/text/RSTypography'
import { CreateWeeklyOutlookFeedback } from '../../modules/weeklyOutlookActions'

export type ProvideFeedbackProps = {
	weeklyOutlook: any
	setWeeklyOutlook?: (data: any) => void
	setOpen?: (open: boolean) => void
}

const ProvideFeedback: FC<ProvideFeedbackProps> = ({ weeklyOutlook, setWeeklyOutlook, setOpen }) => {
	const [rate, setRate] = useState<number | undefined>(weeklyOutlook?.details?.feedback_rate || 0)
	const [newComment, setNewComment] = useState<string>(weeklyOutlook?.details?.feedback_message || '')
	const [loading, setLoading] = useState<boolean>(false)
	const handleSubmit = async () => {
		setLoading(true)
		const data = { ...weeklyOutlook.details, feedback_rate: rate as number, feedback_message: newComment }
		await CreateWeeklyOutlookFeedback(data)
		if (setWeeklyOutlook) {
			setWeeklyOutlook({ ...weeklyOutlook, details: data })
		}
		setLoading(false)
		setOpen && setOpen(false)
	}

	return <Box sx={{ p: 3, px: 2, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
		<H1 size={2} >Provide Feedback</H1>
		<P1 sx={{ textAlign: 'center', pt: 2, maxWidth: '400px' }}>
			Your feedback will help us improve the messages you receive, and, in turn, your running!
		</P1>
		<Box sx={{ position: 'relative', mt: 2, display: 'flex' }}>
			{[...Array(5)].map((_, d: any) => {
				const day = d + 1
				const isActive = rate && rate === day
				return <Box key={d + '-dpw'} sx={{ height: '50px', width: '45px', ml: d ? 1 : 0 }}>
					<RSButton2 ghost={!isActive} onClick={() => setRate(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%' }}
					>
						<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px', color: '#010101' }}>{day}</Box>
					</RSButton2>
				</Box>
			})}
		</Box>
		<Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
			<P2>
				Not helpful at all
			</P2>
			<P2>
				Extremely helpful
			</P2>
		</Box>

		<Box className='transition-all-quarter' sx={{ width: '100%', height: rate && rate < 4 ? '150px' : 0, overflow: 'hidden', maxWidth: '300px', mt: 2 }}>
			<textarea className='text-area-complete center-placeholder' placeholder='Tell us more' value={newComment} onChange={(e) => setNewComment(e.target.value)} style={{ height: '100%' }} />
		</Box>

		<RSButton1 onClick={handleSubmit} disabled={rate === undefined || loading} sx={{ mt: 3, width: '200px' }}>
			{loading ? 'Submitting...' : 'Submit'}
		</RSButton1>
	</Box>
}

export default ProvideFeedback;
