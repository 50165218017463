/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Typography } from '@mui/material'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import WithAuth from '../../components/WithAuth'
import { SH1 } from '../../components/text/RSTypography'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useQueryParams from '../../hooks/useQueryParams'
import useRNBridge from '../../hooks/useRNBridge'
import { getReq, host, navReq, postReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore'
import { IComponentStore } from '../../store/createComponentStore'
import { IGarminStore } from '../../store/createGarminStore'
import useStore from '../../store/useStore'
import integrations from './integrations.json'
import { COROS_CLIENT_ID, COROS_URL } from '../../modules/cliEnvValues'
import { authorizeCorosUser, deAuthorizeCorosUser, getCorosUserByUserId, getUserWorkouts } from '../../modules/corosActions'

const ALLOWED_USER = [16123]

const IntegrationsMainPage: FC<{ type?: string, side?: boolean, reload?: () => void, userInfo?: any }> = ({ userInfo, type, side, reload }) => {
    const { setToast } = useStore((state: IComponentStore) => state)
    const { setGarminDailyDetails, setCurrentDayActivities } = useStore((state: IGarminStore) => state)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCoros, setIsLoadingCoros] = useState(false)
    const [isLoadingApplePermission, setIsLoadingApplePermission] = useState(false)
    const [verifyPageUrl, setVerifyPageUrl] = useState()
    const [userGarminDetails, setUserGarminDetails] = useState<any>()
    const [integrationStatus, setIntegrationStatus] = useState<string>()
    const { oauth_token, oauth_verifier, code, state } = useQueryParams()
    const { sendDataToReactNative } = useRNBridge()
    const { mobileType } = useDeviceDetect()
    const { setPermission, permission: appleHealthPermission } = useStore((state: IAppleHealthKitStore) => state)
    const deviceId = localStorage.getItem('deviceRequestKey')

    const isNativeIOS = deviceId && mobileType.toLowerCase() === 'ios'

    const [corosUserData, setCorosUserData] = useState<any>()

    const getGarminDetails = async () => {
        const response = await getReq('/v2/garmin/user')
        const data = response.data.result[0]
        // console.log(data)
        setUserGarminDetails(data)
    }

    const handleRequestAppleHealthPermission = async () => {

        setIsLoadingApplePermission(true)

        const dataToSend = {
            requestType: 'healthkit-request-permission',
            payload: {
                user_id: userInfo?.account_id || userInfo?.internal_id,
            },
            timestamp: new Date().getTime(),
        }

        sendDataToReactNative(dataToSend)

    }

    const handelGetDailyActivities = async () => {
        const authToken = userGarminDetails?.access_token
        const authSecret = userGarminDetails?.token_secret
        const start = moment().subtract(1, 'days').subtract(12, 'hours')
        const uploadStartTimeInSeconds = Math.floor(start.clone().startOf('day').valueOf() / 1000);
        const uploadEndTimeInSeconds = Math.floor(start.clone().startOf('day').add(86400, 'seconds').valueOf() / 1000);

        const response = await getReq(`/v2/garmin/user/dailyActivityDetails?access_token=${authToken}&token_secret=${authSecret}&uploadStartTimeInSeconds=${uploadStartTimeInSeconds}&uploadEndTimeInSeconds=${uploadEndTimeInSeconds}`)
        // console.log(uploadStartTimeInSeconds, uploadEndTimeInSeconds)
        // console.log(response)
    }

    const handleDisconnectAppleHealth = () => {
        setIsLoadingApplePermission(true)
        localStorage.removeItem('healthkit-permission')
        setPermission(undefined)
        setIsLoadingApplePermission(false)
    }

    const handleDisconnectCoros = () => {
        setIsLoadingCoros(true)
        deAuthorizeCorosUser(Number(userInfo?.account_id)).then((res) => {
            if (res) {
                getCorosUserByUserId(Number(userInfo?.account_id)).then((res) => {
                    const user = res.data.result[0]
                    if (user)
                        setCorosUserData(user)
                })
            }
            setIsLoadingCoros(false)
        })
    }

    const handleDisconnect = async () => {
        try {
            setIsLoading(true)
            const response = await postReq('/v2/garmin/deregister', {
                access_token: userGarminDetails?.access_token,
                token_secret: userGarminDetails?.token_secret,
                garmin_id: userGarminDetails?.garmin_id
            })
            if (response?.status == "ok") {
                await getGarminDetails()
                setIsLoading(false)
                setGarminDailyDetails([])
                setCurrentDayActivities([])
                navReq('/#/')
            }

        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }

    }

    const handleIntegrate = async (url: string) => {

        try {
            setIsLoading(true)
            const response = await getReq(url)
            if (response && response?.status === "ok") {
                const data = response?.data;
                localStorage.setItem('authSecret', data?.authSecret)
                localStorage.setItem('authToken', data?.authToken)
                setVerifyPageUrl(data?.verifyParams)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setIntegrationStatus("error")
            }
        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }

    }

    const handleVerifyAuth = async () => {
        const authToken = localStorage.getItem('authToken')
        const authSecret = localStorage.getItem('authSecret')
        try {
            setIsLoading(true)
            const response = await getReq(`/v2/garmin/verify-auth?oauth_verifier=${oauth_verifier}&auth_token=${authToken}&auth_secret=${authSecret}`)
            if (response && response?.status === "ok") {
                setIsLoading(false)
                localStorage.setItem('dismissSync', '0')
                setIntegrationStatus("success")
                // type === "training-plan" ? navReq('/#/training-plan/view') : undefined
                await getGarminDetails()
                reload && reload()
            }
            else {
                setIsLoading(false)
                setIntegrationStatus("error")
            }
        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }
    }

    const handleCorosIntegration = async () => {
        const authUrl = `${COROS_URL}/oauth2/authorize?client_id=${COROS_CLIENT_ID}&state=${userInfo?.account_id || userInfo?.internal_id}&response_type=code&redirect_uri=https://app.runsmartonline.com/#/profile`
        setIsLoadingCoros(true)
        setTimeout(() => {
            if (userInfo && (userInfo?.account_id || userInfo?.internal_id)) {
                window.location.assign(authUrl)
                setIsLoadingCoros(false)
            }
            else
                setIsLoadingCoros(false)
        }, 1000)
    }

    useEffect(() => {
        if (type === "training-plan") {
            if (integrationStatus)
                setToast({
                    show: integrationStatus ? true : false,
                    title: integrationStatus == "error" ? "Error Message" : "Integration successful!",
                    message: integrationStatus == "error" ? "An error occurred during integration, please try again." : "You have completed connecting your Garmin account!",
                    variant: integrationStatus == "error" ? "danger" : "success",
                    onClose: () => {
                        setIntegrationStatus(undefined)
                        // navReq('/#/')
                    },
                    duration: 5000
                })
        }
        else {
            setToast({
                show: integrationStatus ? true : false,
                title: integrationStatus == "error" ? "Error Message" : "Integration successful!",
                message: integrationStatus == "error" ? "An error occurred during integration, please try again." : "You have completed connecting your Garmin account!",
                variant: integrationStatus == "error" ? "danger" : "success",
                onClose: () => {
                    setIntegrationStatus(undefined)
                    // navReq('/#/')
                },
                duration: 5000
            })
        }

    }, [integrationStatus, type])

    useEffect(() => {
        if (verifyPageUrl) {
            window.location.assign(verifyPageUrl)
        }
    }, [verifyPageUrl])

    useEffect(() => {
        if (oauth_token && oauth_verifier)
            handleVerifyAuth()
    }, [oauth_token, oauth_verifier])

    useEffect(() => {
        if (userInfo)
            getGarminDetails()
    }, [userInfo])

    useEffect(() => {
        if (userInfo && userInfo?.account_id) {
            getCorosUserByUserId(userInfo?.account_id).then((res) => {
                const user = res.data.result[0]
                if (user)
                    setCorosUserData(user)
            })
        }
    }, [userInfo])

    useEffect(() => {
        if (code && state && userInfo && userInfo.account_id == Number(state) && !corosUserData) {
            setIsLoadingCoros(true)
            authorizeCorosUser(code, Number(state)).then((res) => {
                if (res) {
                    getCorosUserByUserId(Number(state)).then((res) => {
                        const user = res.data.result[0]
                        if (user)
                            setCorosUserData(user)
                    })
                }
                setIsLoadingCoros(false)
            })
        }
    }, [code, state, userInfo, corosUserData])

    useEffect(() => {
        const permission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
        if (permission.status === "granted") {
            setPermission(permission)
        }
        return () => {
            setIsLoading(false)
        }
    }, [])

    // useEffect(() => {
    //     if (corosUserData && corosUserData.status == 1 && userInfo && userInfo.account_id == corosUserData.user_id) {
    //         const activity_date = moment().format('YYYY-MM-DD')
    //         getUserWorkouts(corosUserData.user_id, activity_date).then((res) => {
    //             if (res) {
    //                 console.log(res)
    //             }
    //         })
    //     }
    // }, [corosUserData, userInfo])

    useEffect(() => {
        // alert(JSON.stringify(appleHealthPermission))
        // setAppleHealthPermission(HkPermissions)
        if (appleHealthPermission?.status === "granted") {
            setIntegrationStatus("success")
            setIsLoadingApplePermission(false)
        }
        else if (appleHealthPermission?.status === "denied") {
            setIntegrationStatus("error")
            setIsLoadingApplePermission(false)
        }
    }, [appleHealthPermission])

    return (
        <Row>
            {
                type === "training-plan" ? undefined : (!side && <SH1 size={1} sx={{ width: '100%', mb: 2 }}>Integrations</SH1>)
            }

            <>
                <Card onClick={() => { type ? handleIntegrate("/v2/garmin/connect-url") : undefined }} key={1} className={`${!type ? 'no-border' : 'mt-1 mx-md-3 cursor-pointer'} bg-white`}>
                    <img className='p-3 mb-0' style={{ width: '200px', margin: type ? '10px auto' : '0 auto' }} src={"\\images\\icons\\connect-logo-black.svg"}></img>
                    {
                        userGarminDetails && userGarminDetails['status'] == 0 || !userGarminDetails ? (
                            <div className='px-3 mb-3'>
                                {/* <Typography className='mb-2' variant='subtitle1'>
                                            Status:<Typography component={'span'} variant='subtitle1' color={"InactiveBorder"}> Disconnected</Typography>
                                        </Typography> */}
                                {!type ? (
                                    <Button disabled={appleHealthPermission && appleHealthPermission?.status === "granted" || corosUserData && corosUserData.status == 1} onClick={() => handleIntegrate("/v2/garmin/connect-url")} fullWidth variant="outlined" color="success">
                                        {isLoading ? (<Spinner animation='border' />) : 'Connect'}
                                    </Button>
                                ) : undefined}
                            </div>
                        ) : userGarminDetails && userGarminDetails['status'] == 1 ? (
                            <div className='px-3 mb-3'>
                                <p className='font-12'>Status: <Typography component={'span'} variant='body2' color={"green"}> Connected</Typography>
                                    <br />
                                    Last Connected: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{moment(userGarminDetails?.last_modified).format('MMMM D, YYYY h:mm A')}</Typography>
                                    <br />
                                    Garmin Id: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{userGarminDetails?.garmin_id}</Typography>

                                    <br />
                                    Permissions: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{userGarminDetails?.permissions.split(',').map((x: string, key: number) => (
                                        key != userGarminDetails?.permissions.split(',').length - 1 ? x.split('_')[0] + ' | ' : x.split('_')[0])
                                    )}
                                    </Typography>
                                </p>

                                <Button onClick={handleDisconnect} fullWidth variant="outlined" color="error">
                                    {isLoading ? (<Spinner animation='border' />) : 'Disconnect'}
                                </Button>
                            </div>
                        ) : undefined
                    }
                    {isLoading && type ? (<div className='text-center mb-3'><Spinner animation='border' /></div>) : undefined}
                </Card>
                {
                    isNativeIOS && (
                        <Card onClick={() => { type && (!appleHealthPermission || !appleHealthPermission?.status) ? handleRequestAppleHealthPermission() : undefined }} key={2} className={`${!type ? 'no-border mt-2' : 'mt-2 mx-md-3 cursor-pointer'} bg-white`}>
                            <h4 className='text-center w-100 my-4 mb-2' style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Apple Watch</h4>
                            {/* <h4 className='w-100 mb-3' style={{ fontSize: '0.8rem', textAlign: 'center', marginTop: '-12px' }}>Powered by HealthKit</h4> */}
                            {
                                appleHealthPermission?.status !== "granted" ? (
                                    <div className='px-3 mb-3'>
                                        {/* <Typography className='mb-2' variant='subtitle1'>
                                            Status:<Typography component={'span'} variant='subtitle1' color={"InactiveBorder"}> Disconnected</Typography>
                                        </Typography> */}
                                        {!type ? (
                                            <Button disabled={(userGarminDetails && userGarminDetails['status'] == 1 || corosUserData && corosUserData.status == 1)} onClick={() => handleRequestAppleHealthPermission()} fullWidth variant="outlined" color="success">
                                                {isLoadingApplePermission ? (<Spinner animation='border' />) : 'Connect'}
                                            </Button>
                                        ) : undefined}
                                    </div>
                                ) : appleHealthPermission?.status === "granted" ? (
                                    <div className='px-3 mb-3'>
                                        <p className='font-12'>Status: <Typography component={'span'} variant='body2' color={"green"}> Connected</Typography>
                                            <br />
                                            Last Connected: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{moment(appleHealthPermission?.date).format('MMMM D, YYYY h:mm A')}</Typography>
                                            <br />
                                            Apple Id: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{deviceId}</Typography>
                                            <br />
                                            Permissions: {JSON.stringify(appleHealthPermission?.permissions).replace(/"/g, '').replace(/,/g, ' | ').replaceAll('[', '').replaceAll(']', '')}
                                            <br />
                                        </p>

                                        <Button onClick={handleDisconnectAppleHealth} fullWidth variant="outlined" color="error">
                                            {isLoadingApplePermission ? (<Spinner animation='border' />) : 'Disconnect'}
                                        </Button>
                                    </div>
                                ) : undefined
                            }
                            {isLoadingApplePermission && type ? (<div className='text-center mb-3'><Spinner animation='border' /></div>) : undefined}
                        </Card>
                    )
                }
                {/* {userInfo?.account_id && ALLOWED_USER.includes(userInfo?.account_id) && ( */}
                <Card onClick={() => { type ? handleCorosIntegration() : undefined }} key={1} className={`${!type ? 'no-border' : 'mt-1 mx-md-3 cursor-pointer'} bg-white`}>
                    <img className='p-3 mb-0' style={{ width: '200px', margin: type ? '10px auto' : '0 auto' }} src={"\\img\\coros_logo3.png"}></img>
                    {corosUserData && corosUserData.status == 1 ? (
                        <>
                            <div className='px-3 mb-3'>
                                <p className='font-12'>Status: <Typography component={'span'} variant='body2' color={"green"}> Connected</Typography>
                                    <br />
                                    Last Connected: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{moment(corosUserData?.last_modified).format('MMMM D, YYYY h:mm A')}</Typography>
                                    <br />
                                    COROS Id: <Typography component={'span'} variant='body2' color={"ActiveCaption"}>{corosUserData?.open_id}</Typography>
                                </p>

                                <Button onClick={handleDisconnectCoros} fullWidth variant="outlined" color="error">
                                    {isLoadingCoros ? (<Spinner animation='border' />) : 'Disconnect'}
                                </Button>
                            </div>
                        </>
                    ) :
                        (
                            <div className='px-3 mb-3'>
                                {type && isLoadingCoros ? (<div style={{ width: "100%", textAlign: "center" }}><Spinner animation='border' /></div>) : undefined}
                                {!type ? (
                                    <Button disabled={appleHealthPermission && appleHealthPermission?.status === "granted" || userGarminDetails && userGarminDetails['status'] == 1} onClick={handleCorosIntegration} fullWidth variant="outlined" color="success">
                                        {isLoadingCoros ? (<Spinner animation='border' />) : 'Connect'}
                                    </Button>
                                ) : undefined}
                            </div>
                        )}
                </Card>
                {/* )} */}
            </>
        </Row>
    )
}

export default IntegrationsMainPage
