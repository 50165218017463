/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { motion } from "framer-motion";
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { RSButton2 } from '../../../../components/buttons'
import RSInput from '../../../../components/input/RSInput'

export type PrivateInfoProps = {
	done?: any,
	bodyData?: any,
	setBodyData?: any,
	nextAvailability?: any
}

const PrivateInfo: FC<PrivateInfoProps> = ({ done, bodyData, setBodyData, nextAvailability }) => {
	const [firstName, setFirstName] = useState<any>(bodyData?.firstName ? bodyData.firstName : '')
	const [lastName, setLastName] = useState<any>(bodyData?.lastName ? bodyData.lastName : '')
	const [birthDate, setBirthDate] = useState<any>(bodyData?.birthDate ? bodyData.birthDate : '')
	const [gender, setGender] = useState<any>(bodyData?.gender ? bodyData.gender : '')
	const [isMetric, setIsMetric] = useState(bodyData?.isMetric ? bodyData.isMetric : 0);
	const metricList = [{
		title: 'Imperial',
		desc: 'mi/lbs'
	}, {
		title: 'Metric',
		desc: 'km/kg'
	}]

	const renderTextField = (title: string, item: any, setItem: any) => {
		return <RSInput value={item} setValue={setItem} title={title}/>
	}

	useEffect(() => {
		const newFN = firstName?.trim()
		const newLN = lastName?.trim()
		setBodyData({
			...bodyData,
			firstName: newFN,
			lastName: newLN,
			birthDate,
			gender,
			isMetric,
		})
		nextAvailability(newFN && newLN && birthDate && gender)
	}, [firstName, lastName, isMetric, birthDate, gender])

	return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
		<Grid container xs={12} sx={{ pb: '15px', lineHeight: '1.2em' }} justifyContent='space-between' >
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				Your name
			</Grid>
			<Grid container xs={5.5}>
				{renderTextField('First', firstName, setFirstName)}
			</Grid>
			<Grid container xs={5.5}>
				{renderTextField('Last', lastName, setLastName)}
			</Grid>
		</Grid>

		<Grid container xs={12} sx={{ pb: '15px', lineHeight: '1.2em' }} justifyContent='space-between' >
			<Grid container xs={5.5} sx={{ pt: '5px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				Date of birth
			</Grid>
			<Grid container xs={5.5} sx={{ pt: '5px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				Gender
			</Grid>
			<Grid container xs={5.5}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DemoItem>
						<DatePicker
							sx={{
								'& .MuiOutlinedInput-root': {
									height: '45px'
								},
								'& .MuiInputBase-input': {
									mt: '4px',
									zIndex: 1
								},
								'& .MuiOutlinedInput-notchedOutline': {
									backgroundColor: 'white'
								},
								width: '100%',
								pt: '3px'
							}}
							slotProps={{
								textField: {
									error: false
								}
							}}
							maxDate={dayjs(moment().format('YYYY/MM/DD'))}
							openTo="year"
							value={dayjs(moment(new Date(birthDate)).format('YYYY/MM/DD'))}
							onChange={(newValue: any) => setBirthDate(moment(new Date(newValue)).format('YYYY/MM/DD'))}

						/>
					</DemoItem>
				</LocalizationProvider>
			</Grid>
			<Grid container xs={5.5}>
				<FormControl sx={{ mt: '10px', width: '100%' }} size="small">
					<InputLabel id="demo-select-small-label">Select</InputLabel>
					<Select
						sx={{ height: '38px', backgroundColor: 'white' }}
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={gender}
						label="Gender"
						onChange={(e: SelectChangeEvent) => setGender(e.target.value as string)}
					>
						<MenuItem value={'m'}>Male</MenuItem>
						<MenuItem value={'f'}>Female</MenuItem>
						<MenuItem value={'nb'}>Non-Binary</MenuItem>
					</Select>
				</FormControl>
			</Grid>
		</Grid>
		<Grid container xs={12} justifyContent="flex-start" alignItems="center">
			<Grid container xs={12} sx={{ pt: '5px', fontFamily: 'Poppins-Medium', fontSize: '14px', pb: '10px' }}>
				Measurement System
			</Grid>
			<Grid container xs={12} justifyContent='space-between'>
				{metricList.map((activity: any, ai: number) => {
					const isActive = ai === isMetric
					return <Grid item container xs={5.6} key={'activity-' + ai} sx={{ minHeight: '60px', width: '100px', mb: '20px' }} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{ height: '100%', flexDirection: 'column' }} onClick={() => setIsMetric(ai)}>
							<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px' }}>{activity.title}</Box>
							{activity.desc && <Box sx={{ fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center' }}>{activity.desc}</Box>}
						</RSButton2>
					</Grid>
				})}
			</Grid>
		</Grid>
	</Box>
}

export default PrivateInfo
