import { FC } from 'react'

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export type RSTypographyProps = {
	className?: string,
	children?: any,
	sx?: object,
	onClick?: any,
	size?: number,
	desc?: boolean,
	title?: boolean,
	subtitle?: boolean,
	card?: boolean,
}

const handleClick = (e: any, onClick?: any) => {
	onClick?.(e)
}

const getFontFamily = (props: {sub?: boolean, par?: boolean, cap?: boolean}) => {
	const { sub, par, cap } = props
	return `Poppins${sub ? '-SemiBold' : (cap ? '-Light' : (par ? '' : '-Bold'))} !important`
}

const titleSX = { width: '100%', textAlign: 'left', textTransform: 'uppercase', pb: '2px' }
const subtitleSX = { width: '100%', textAlign: 'left'}

const getFontSize = (size?: number) => `${14+(2*(size || 0))}px !important`

export const H1: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card }) => {
	return <Typography variant="h1" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const H2: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card }) => {
	return <Typography variant="h2" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const H3: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card }) => {
	return <Typography variant="h3" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const H4: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card  }) => {
	return <Typography variant="h4" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const H5: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card }) => {
	return <Typography variant="h5" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const H6: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, card }) => {
	return <Typography variant="h6" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH1: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card }) => {
	return <Typography variant="h1" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH2: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card }) => {
	return <Typography variant="h2" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH3: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card }) => {
	return <Typography variant="h3" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH4: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card }) => {
	return <Typography variant="h4" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH5: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card  }) => {
	return <Typography variant="h5" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const SH6: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, title, card  }) => {
	return <Typography variant="h6" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : 1}em`, textTransform: 'none !important', ...title && titleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const P1: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, desc, subtitle, card  }) => {
	return <Typography variant="body1" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({par: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : (desc ? 7 : 1)}em`, textTransform: 'none !important', ...subtitle && subtitleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const P2: FC<RSTypographyProps> = ({ className = '', sx = {}, children, onClick, size, desc, subtitle, card  }) => {
	return <Typography variant="body1" className={className} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({cap: true}), letterSpacing: 'normal !important', lineHeight: `1.${card ? 3 : (desc ? 7 : 1)}em`, textTransform: 'none !important', ...subtitle && subtitleSX, ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Typography>
}

export const A: FC<RSTypographyProps & {link?: string, underline?: boolean, newWindow?: boolean, p1?: boolean, p2?: boolean}> = ({ className = '', sx = {}, children, onClick, size, link = '', underline, newWindow, p1, p2 }) => {
	return <Link {...link && {href: link}} target={newWindow ? "_blank" : ''} underline={underline ? 'always' : 'none'} className={`pointer blue-text blue-text-decoration ${className}`} sx={{fontSize: getFontSize(size), fontFamily: getFontFamily({sub: !p1 && !p2 ? true : false, par: p1 ? true : false, cap: p2 ? true : false}), letterSpacing: 'normal !important', lineHeight: '1.1em', textTransform: 'none !important', color: '', ...sx}} onClick={(e: any)=>handleClick(e, onClick)}>
		{children}
	</Link>
}





