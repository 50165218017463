/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { RSButton2 } from '../../../../components/buttons'
import { H1, SH2, P1 } from '../../../../components/text/RSTypography'
import helpList from '../../suggested-program.json'

export type SetYourGoalsProps = {
	done?: any,
	bodyData?: any,
	setBodyData?: any,
	nextAvailability?: any
}

const helpList1 = [
	'Get back in shape',
	'Prevent Injury',
	'Get Stronger',
	'Improve my Run Form',
	'Recover from Race',
	'Others'
]

const SetYourGoals: FC<SetYourGoalsProps> = ({ done, bodyData, setBodyData, nextAvailability }) => {
	const [goals, setGoals] = useState<Array<any>>(bodyData?.goals?.length ? bodyData.goals.map((g: any) => g.index) : []);
	const o = bodyData?.goals?.find((g: any) => g.index === helpList.length - 1)?.title?.others
	const [others, setOthers] = useState(o ? o : '')

	useEffect(() => {
		setBodyData({
			...bodyData,
			goals: goals?.map((g: any) => ({ index: g, title: helpList[g] }))
		})
		nextAvailability(goals?.length)
	}, [goals, others])

	return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
		<Grid container sx={{ pb: 2 }} justifyContent='flex-start' alignItems='center'>
			<H1 size={5}>
				Set Your Goals
			</H1>
		</Grid>
		<P1 sx={{ pb: 3, width: '100%' }}>
			Conquer a new distance, set a PR, or improve your running. RunSmart can provide you with all the tools.
		</P1>
		<Grid container xs={12} sx={{ pb: '5px' }} justifyContent='space-between' >
			<SH2 sx={{pb: 2}}>
				How can we help?
			</SH2>
			<Grid container xs={12} justifyContent='space-between'>
				{helpList.map((helpData: any, ai: number) => {
					const fndR = goals.indexOf(ai)
					const isActive = fndR > -1
					return <Grid item container xs={5.6} key={'activity-' + ai} sx={{ height: '60px', width: '100px', mb: '15px' }} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{ height: '100%', flexDirection: 'column' }}
							onClick={() => {
								//setGoals([ai])
								const rs = [...goals]
								if (isActive) {
									rs.splice(fndR, 1);
									setGoals(rs)
								} else
									setGoals([...rs, ai])
							}}>
							<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px' }}>{helpData.details}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>
			{goals?.indexOf(helpList.length - 1) > -1 && helpList?.[helpList.length - 1]?.details === 'Others' && <Grid container xs={12} justifyContent='center' alignItems="center">
				<textarea style={{ height: '80px' }} className='text-area-complete' placeholder='Tell us!' value={others} onChange={(e) => setOthers(e.target.value)} />
			</Grid>}
		</Grid>
	</Box>
}

export default SetYourGoals
