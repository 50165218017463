import { CorosScheduledWorkout } from "../api/v2/coros/coros.scheduled.types"
import { getReq, postReq } from "./apiConsume"

export const authorizeCorosUser = async (code: string, userId: number) => {
    const authResponse = await postReq('/v2/coros/authorize', { code, userId })
    return authResponse
}

export const deAuthorizeCorosUser = async (userId: number) => {
    const response = await postReq('/v2/coros/deauthorize', { userId })
    return response.data
}

export const getCorosUserByUserId = async (userId: number) => {
    const response = await getReq('/v2/coros/user?user_id=' + userId)
    return response.data
}

export const getUserWorkouts = async (userId: number, activity_date: string) => {
    const response = await getReq(`/v2/coros/workouts?user_id=${userId}&activity_date=${activity_date}`)
    return response.data.data
}

export const getUserWorkoutsByRange = async (userId: number, start_date: string, end_date: string) => {
    const response = await getReq(`/v2/coros/workouts/range?user_id=${userId}&start_date=${start_date}&end_date=${end_date}`)
    return response.data.data
}

export const getCorosScheduledWorkouts = async (userId: number, activity_date: string, mar_id: number) => {
    const response = await getReq(`/v2/coros/scheduled-workouts?user_id=${userId}&activity_date=${activity_date}&mar_id=${mar_id}`)
    return response.data.data
}

export const pushScheduledWorkouts = async (workoutData: CorosScheduledWorkout) => {
    const response = await postReq('/v2/coros/push-workouts', workoutData)
    return response.data
}