import { FC, useState} from 'react'
import RSCardCont from '../../../components/cards/RSCardCont';
import { SH3, P2 } from '../../../components/text/RSTypography'
import { Box, Grid } from '@mui/material';

interface WorkoutVideoContainerProps {
    thumbnail: string,
    index: number,
    headingTitle: string,
    subTitle: string,
    onClick?: any,
    duration: number,
    isDurationLoading?: boolean
}

const WorkoutVideoContainer: FC<WorkoutVideoContainerProps> = ({ onClick, index, thumbnail, headingTitle, subTitle, duration, isDurationLoading = false }) => {
		const [imgLoading, setImgLoading] = useState(true)
    return (
        <RSCardCont onClick={() => onClick?.()} key={index} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', border: 'none !important' }}>
						<Box sx={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<Grid container xs={4.5} className='card-slanted' sx={{ position: 'relative', height: '80px', zIndex: 2 }} justifyContent='center'>
									<img onLoad={()=> setImgLoading(false)} src={thumbnail} style={{height: '100%', width: '100%'}}/>
									{/*<Box className='card-slanted' sx={{ backgroundSize: 'cover', backgroundRepeat: "no-repeat", backgroundImage: `url("${thumbnail}")`, position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', backgroundPosition: 'center', alignItems: 'center' }}/>*/}
								</Grid>
								<Grid container xs={7.5} sx={{ height: '80px', position: 'relative', }}>
										<Grid container xs={12} sx={{ px: 2, flexDirection: 'column', height: '80px', zIndex: 3, background: '#FFF' }} justifyContent='center' alignItems='flex-start'>
												<SH3 title>{headingTitle}</SH3>
												<P2 subtitle>{subTitle}</P2>
												<P2 subtitle sx={{ fontStyle: 'italic', position: 'relative', width: 'auto' }}>
													{isDurationLoading && <Box className='loading-days toRight' sx={{border: 'none !important', position: 'absolute', width: 'calc(100% + 5px)', height: '100%'}} />}
													{Math.round(duration / 60)} min
												</P2>
										</Grid>
								</Grid>
								{imgLoading && <Box className='card-slanted loading-days toRight' sx={{border: 'none !important', position: 'absolute', height: 'calc(100% + 1px)', width: 'calc(37.5% + 3px)', top: '50%', left: 0, transform: 'translate(-1px, -50%)'}} />}
						</Box>
				</RSCardCont >
    )
}

export default WorkoutVideoContainer
