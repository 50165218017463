import { IRaceDetails, IRaceType } from "./RaceType";

export const RaceDetails: IRaceDetails[] = [
    {
        //index 0 for 20-40 mile plan
        milesValues: [20, 30, 40],
        milesPerWeek: ["15 - 20", "23 - 30", "29 - 40"],
        runsPerWeek: ["4", "5 - 6", "5 - 6"],
        kmsValues: [35, 50, 65],
    },
    {
        //index 1 for 30-60 mile - half marathon
        milesValues: [30, 40, 50, 60],
        milesPerWeek: ["18 - 30", "25 - 40", "30 - 50", "35 - 60"],
        runsPerWeek: ["4 - 5", "4 - 5", "5 - 6", "6 - 7"],
        kmsValues: [50, 65, 80, 95],
    },
    {
        //index 2 for 40-70 mile - full marathon
        milesValues: [40, 50, 60, 70],
        milesPerWeek: ["25 - 40", "30 - 50", "35 - 60", "40 - 70"],
        runsPerWeek: ["4 - 5", "5 - 6", "6 - 7", "6 - 7"],
        kmsValues: [65, 80, 95, 110],
    }
]

export const RaceOptions: IRaceType[] = [
    {
        id: 1,
        value: 2,
        title: "5K",
        subTitle: "",
        week: "10-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/5k_logo.png',
				home_logo: '/img/homepage_logos/5k_logo_blk.png'
    },
    {
        id: 2,
        value: 0,
        title: "HALF",
        subTitle: "MARATHON",
        week: "14, 16-Week Plans",
        weekOptions: [14, 16],
        mile: "30-60 Mile Plans",
        details: RaceDetails[1],
        img: '/img/homepage_logos/half_marathon_logo.png',
				home_logo: '/img/homepage_logos/half_marathon_logo_blk.png'
    },
    {
        id: 3,
        value: 3,
        title: "10K",
        subTitle: "",
        week: "10-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/10k_logo.png',
				home_logo: '/img/homepage_logos/10k_logo_blk.png'
    },
    {
        id: 4,
        value: 1,
        title: "FULL",
        subTitle: "MARATHON",
        week: "16, 18-Week Plans",
        weekOptions: [16, 18],
        mile: "40-70 Mile Plans",
        details: RaceDetails[2],
        img: '/img/homepage_logos/full_marathon_logo.png',
				home_logo: '/img/homepage_logos/full_marathon_logo_blk.png'
    },
    {
        id: 5,
        value: 4,
        title: "MAINTENANCE",
        subTitle: "Plans",
        week: "10-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/maintenance_logo.png',
				home_logo: '/img/homepage_logos/maintenance_logo_blk.png'
    },
    {
        id: 7,
        value: 7,
        title: "RECOVER",
        subTitle: "Plans",
        week: "5-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/recover_logo.png',
				home_logo: '/img/homepage_logos/recover_logo_blk.png'
    },
    {
        id: 8,
        value: 5,
        title: "8K",
        subTitle: "",
        week: "10-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/8k_logo.png',
				home_logo: '/img/homepage_logos/8k_logo_blk.png'
    },
    {
        id: 11,
        value: 8,
        title: "10 Mile",
        subTitle: "",
        week: "10-Week Plans",
        weekOptions: [10],
        mile: "20-40 Mile Plans",
        details: RaceDetails[0],
        img: '/img/homepage_logos/10mi_logo.png',
				home_logo: '/img/homepage_logos/10mi_logo_blk.png'
    },
]
