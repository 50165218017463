/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReplayRounded, SendRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, InputLabel, MenuItem, Select, Button } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import RSButton from '../../components/buttons/RSButton'
import RSInputAdornment from '../../components/input/RSInputAdornment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'
import { WeeklyOutlookPrompt } from '../../api/v2/weekly-outlook/weekly-outlook.types'

interface Props {
    data?: WeeklyOutlookPrompt
    isLoading: boolean
    submit: (data: WeeklyOutlookPrompt) => Promise<boolean>
    setSelectedPrompt: (prompt: WeeklyOutlookPrompt | undefined) => void
    lastVersion?: number
    setStep: (step: string) => void
    step: string
}

const WeeklyOutlookPromptsForm: FC<Props> = ({ data, isLoading, submit, setSelectedPrompt, lastVersion, setStep, step }) => {
    const weekEnds = ['Saturday', 'Sunday']
    const [longRunDay, setLongRunDay] = useState('Saturday')
    // const [version, setVersion] = useState(String(data?.version) || '');
    const [systemPrompt, setSystemPrompt] = useState('')
    const [notes, setNotes] = useState('')
    const [params, setParams] = useState({
        "planType": "Full Marathon",
        "planName": "Newport Marathon",
        "totalWeeks": 20,
        "currentWeek": 2,
        "unit": "miles",
        "longRunDay": "Saturday",
        "goal": "3:55:00",
        "trainingPeak": 30,
        "dailyRunsWeekCurrent": 20,
        "dailyRunsWeekUpcoming": 30,
        "raceDate": moment().add(1, 'months').format('YYYY-MM-DD'),
        "age": 30,
        "height": 130,
        "weight": 60,
        "data": "",
        "message": "",
    })

    const generatePromptsFromText = (prompt: string, params: any) => {
        return prompt?.replace(/\${(.*?)}/g, (_, expression) => {
            try {
                return new Function('params', `with (params) { return ${expression} }`)(params);
            } catch (e) {
                // console.error(e);
                return ''
            }
        });
    };

    const handleSubmit = async () => {
        console.log(lastVersion)
        const payload: WeeklyOutlookPrompt = {
            ...data,
            version: data && lastVersion ? (+lastVersion.toFixed(1) + 0.1) : !data && lastVersion ? (+lastVersion.toFixed() + 1) : 1,
            step: Number(step),
            prompt: systemPrompt,
            notes: notes,
            status: 0
        }

        await submit(payload).then((result) => {
            if (result) handleResetForm()
        })
    }

    const handleResetForm = () => {
        // setVersion('')
        setStep('')
        setSystemPrompt('')
        setNotes('')
        setSelectedPrompt(undefined)
    }

    useEffect(() => {
        if (data) {
            // setVersion(String(data?.version))
            setStep(String(data?.step))
            setSystemPrompt(data?.prompt)
            setNotes(data?.notes as string)
        }
        else
            handleResetForm()
    }, [data])

    return (
        <div className='half-md-card w-100 card mx-auto p-3 mt-1'>
            <h5><b>{`${data ? 'Update' : 'Create New'} Prompt`}</b></h5>
            <Grid container>
                {/* <Grid spacing={1} container xs={12} sx={{ maxWidth: '210px', pt: 1 }}>
                    <Grid xs={12}>
                        <h5>Select Long Run Day</h5>
                    </Grid>
                    {weekEnds.map((day: string, index: number) => {
                        const isActive = longRunDay === day
                        return <Box key={index + day + '-lr'} sx={{ p: '0px 1px', height: '50px', width: '100px' }}>
                            <Button onClick={() => setLongRunDay(day)} sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101' }}
                            >
                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{day}</Box>
                            </Button>
                        </Box>
                    })}
                </Grid> */}
                {/* to change to dropdown selection */}
                {/* <RSInputAdornment disabled={data ? true : false} value={version} setValue={setVersion} label={'Version'} arial={''} /> */}
                <RSInputAdornment disabled={data ? true : false} value={String(step)} setValue={setStep} label={'Step'} arial={''} />

                <Form.Control
                    className='mt-2'
                    value={systemPrompt}
                    type="text"
                    as="textarea"
                    rows={20}
                    placeholder="System Prompt"
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    disabled={false}
                />

                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Prompt Preview / Params - Click to Expand
                    </AccordionSummary>
                    <AccordionDetails>
                        <Form.Control
                            className='mt-2'
                            value={generatePromptsFromText(systemPrompt, params)}
                            type="text"
                            as="textarea"
                            rows={20}
                            placeholder="System Prompt Preview"
                            disabled={false}
                        />
                        <hr />
                        <p>Params</p>
                        <Form.Control
                            className='mt-2'
                            value={JSON.stringify(params, null, 2)}
                            type="text"
                            as="textarea"
                            rows={20}
                            placeholder="Prompt Preview Params"
                            onChange={(e) => {
                                try {
                                    setParams(JSON.parse(e.target.value))
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                            }
                            disabled={false}
                        />
                    </AccordionDetails>
                </Accordion>

                <Form.Control
                    className='mt-2'
                    value={notes}
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Notes"
                    onChange={(e) => setNotes(e.target.value)}
                    disabled={false}
                />

                <Grid spacing={1} container>
                    <Grid item xs={4}>
                        <RSButton disabled={isLoading} className='mt-2' onClick={handleResetForm}><ReplayRounded /></RSButton>
                    </Grid>
                    <Grid item xs={8}>
                        <RSButton disabled={isLoading || (!data && (!step || !systemPrompt)) || (data && data.prompt === systemPrompt)} className='mt-2' onClick={handleSubmit}><SendRounded />  {data ? 'Update' : 'Save'} </RSButton>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default WeeklyOutlookPromptsForm