import newVideos from './new-videos.json'
import phpVideos from './php-videos.json'
import videoText from './video-text.json'
import newExercises from './allNewExercises.json'
import { pageUrls } from './videoOrders'
import { titleCase } from '../miscUtils'

// Video Type
export type Video = {
	id: string
	url: string
	category: string
	image: string
	headingTitle: string
	headingSubtitle: string
	detailsLine1: string
	detailsLine2: string
	headlines: string[]
	descriptions: string[],
	date_created?: string | undefined,
	type?: string | undefined,
	duration?: any,
	durationNumber?: number,
	program_id?: number
	intro_status?: number
	program_has_intro?: boolean
}

// Videos Type
type Videos = {
	[index: string]: Video
}

// Get All Videos
const getAllVideos = () => {
	const allNewExercises = newExercises.filter(x => x.video_type === "exercise")
	const allVideos: Videos = {}

	const parseText = (text: string, useTitleCase = false) => {
		if (!text) return { title: '', subtitle: '' }
		let parts: string[] = []
		if (text.includes('-')) {
			parts = text.split('-')
		} else if (text.includes(':')) {
			parts = text.split(':')
		} else if (text.includes('|')) {
			parts = text.split('|')
		}
		if (parts.length) {
			let firstPart = parts[0].trim()
			let secondPart = parts[1].trim()
			if (useTitleCase) {
				firstPart = titleCase(firstPart)
				secondPart = titleCase(secondPart)
			}
			if (secondPart.includes('Day')) {
				return { title: secondPart, subtitle: '' }
			} else {
				return { title: firstPart, subtitle: secondPart }
			}
		}
		return {
			title: useTitleCase ? titleCase(text).trim() : text.trim(),
			subtitle: '',
		}
	}

	// Get Video URL
	const getVideoUrl = (id: string | number) => {
		const numId = Number(id)
		for (const key in pageUrls) {
			const videoIds = pageUrls[key as keyof typeof pageUrls]

			if (key == 'webinars') {
				for (const webinarKey in videoIds) {
					const webinarId = (videoIds as any)[webinarKey]
					if (webinarId == numId) {
						return `/#/webinars/${webinarKey.toLowerCase().replace(/ /g, '-')}`
					}
				}
			}
			// Get Other URL
			else if ((videoIds as number[]).includes(numId)) {
				return `/#/${key}/${numId}`
			}
		}
	}

	// Get Video Details
	const getVideoDetails = (key: string | number, info: any, textInfo: any) => {
		const headingInfo = parseText(info.main_headline)
		const detailsInfo = parseText(info.type, true)
		const image = info.img_title?.includes('.') ? info.img_title : ''
		return {
			id: String(key),
			url: getVideoUrl(key),
			image,
			category: info.item || '',
			headingTitle: headingInfo.title,
			headingSubtitle: headingInfo.subtitle,
			detailsLine1: detailsInfo.title,
			detailsLine2: detailsInfo.subtitle,
			headlines: textInfo.headlines || [],
			descriptions: textInfo.descriptions || [],
			date_created: info?.last_watched || info?.date_created
		} as Video
	}

	// Iterate New Videos
	for (const key in newVideos) {
		// (newVideos as any)[key].type !== "exercise" ?
		allVideos[key] = getVideoDetails(
			key,
			(newVideos as any)[key],
			(videoText as any)[key] || {}
		)
		// : undefined
	}

	// Iterate New Exercises
	for (const key in allNewExercises) {
		allVideos[allNewExercises[key].video_id] = getVideoDetails(
			allNewExercises[key].video_id,
			{
				item: "All Exercises",
				main_headline: allNewExercises[key].exercise_title,
				type: "new exercise",
				img_title: `https://vumbnail.com/${allNewExercises[key].video_id}.jpg`
			},
			{
				headlines: [],
				descriptions: []
			}
		)
	}

	// Iterate PHP Videos
	for (const key in phpVideos) {
		if (!allVideos[key] && (phpVideos as any)[key].item !== "All Exercises")
			allVideos[key] = getVideoDetails(
				key,
				(phpVideos as any)[key],
				(videoText as any)[key] || {}
			)
	}

	return allVideos
}

// All Videos
const allVideos = getAllVideos()

// Export
export default allVideos
