/* eslint-disable @typescript-eslint/no-unused-vars */

import { RefreshOutlined, TaskAltOutlined } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { FaBoxArchive } from 'react-icons/fa6'
import { WeeklyOutlookPrompt } from '../../api/v2/weekly-outlook/weekly-outlook.types'
import RSButton from '../../components/buttons/RSButton'
import { ActivatePromptWeeklyOutlook, ArchivePromptWeeklyOutlook, GetAllPromptWeeklyOutlook, RestorePromptWeeklyOutlook, SavePromptWeeklyOutlook } from '../../modules/weeklyOutlookActions'
import WeeklyOutlookPromptsForm from './WeeklyOutlookPromptsForm'

// interface Props {
//     data: WeeklyOutlookPrompt[]
//     setSelectedPrompt: (prompt: OpenAIPrompt | undefined) => void
//     deletePromptVersion: (id: number) => Promise<void>
//     activatePromptVersion: (prompt: OpenAIPrompt) => Promise<void>
//     restorePromptVersion: (id: number) => Promise<void>
//     isLoading?: boolean
//     selectedPrompt?: OpenAIPrompt
//     setLastVersion: (version: number) => void
// }

const WeeklyOutlookPrompts = () => {

    // const raceTypeSelection = ['All', 'full', 'half', '10k', '8k', '5k', '10 mile', 'maintenance']
    // const [raceType, setRaceType] = useState('All')
    const [filteredStatus, setFilteredStatus] = useState<any>(1)
    const [prompts, setPrompts] = useState<WeeklyOutlookPrompt[]>([])
    const [data, setData] = useState<WeeklyOutlookPrompt[]>([])
    const [stepFilter, setStepFilter] = useState<string[]>([])
    const [selectedStep, setSelectedStep] = useState('All')
    const [showArchived, setShowArchived] = useState(false)
    const [archivePrompts, setArchivedPrompts] = useState<WeeklyOutlookPrompt[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedPrompt, setSelectedPrompt] = useState<WeeklyOutlookPrompt | undefined>()
    const [lastVersion, setLastVersion] = useState<number>()
    const [step, setStep] = useState<string>(String(selectedPrompt?.step) || '');
    const statusSelection = ['All', 'Inactive', 'Active', showArchived && archivePrompts.length ? 'Archived' : undefined]

    // const handleChange = (event: any) => {
    //     setRaceType(event.target.value)
    // }

    const getAvailableSteps = (data: WeeklyOutlookPrompt[]) => {
        if (data && data.length) {
            const steps: string[] = data.map((prompt) => String(prompt.step))
            //return unique steps with no duplicates es2015 way
            return Array.from(new Set(steps)).sort((a: any, b: any) => a.localeCompare(b)) as string[]
        }

        return []
    }

    const getLastVersion = (data: WeeklyOutlookPrompt[], step: string) => {
        const filteredData = data.filter((prompt) => String(prompt.step) == step).sort((a, b) => a.version - b.version)
        if (filteredData.length > 0) {
            return filteredData[filteredData.length - 1].version
        }

        return 0
    }

    const deletePromptVersion = async (id: number) => {
        setIsLoading(true)
        await ArchivePromptWeeklyOutlook(id)
        await LoadData()
        setIsLoading(false)
    }

    const restorePromptVersion = async (id: number) => {
        setIsLoading(true)
        await RestorePromptWeeklyOutlook(id)
        await LoadData()
        setIsLoading(false)
    }

    const activatePromptVersion = async (id: number) => {
        setIsLoading(true)
        await ActivatePromptWeeklyOutlook(id)
        await LoadData()
        setIsLoading(false)
    }

    const handleSubmit = async (prompt: WeeklyOutlookPrompt) => {
        setIsLoading(true)
        await SavePromptWeeklyOutlook(prompt.step, prompt.prompt, prompt.notes, prompt.version)
        await LoadData()
        setIsLoading(false)

        return true
    }

    const LoadData = async () => {
        setIsLoading(true)

        GetAllPromptWeeklyOutlook().then((res) => {
            const results = res.data.result
            if (results && results.length) {
                setData(results)
                setArchivedPrompts(results.filter((prompt: any) => prompt.status === 2))
                setStepFilter(['All', ...getAvailableSteps(results)])
            }

            setIsLoading(false)
        })
    }

    useEffect(() => { LoadData() }, [])


    useEffect(() => {

        const handleFilter = async (prompts: WeeklyOutlookPrompt[]) => {
            let filteredPrompts = showArchived ? prompts : prompts.filter((prompt) => prompt.status !== 2)

            if (selectedStep !== "All")
                filteredPrompts = filteredPrompts.filter((prompt) => String(prompt.step) === selectedStep)

            if (filteredStatus != -1) {
                filteredPrompts = filteredPrompts.filter((prompt) => prompt.status === filteredStatus)
            }

            setPrompts(filteredPrompts)
        }

        handleFilter(data)

    }, [data, filteredStatus, showArchived, selectedStep])

    useEffect(() => {

        if (data && data.length) {
            if (selectedPrompt && step) {
                setLastVersion(getLastVersion(data, String(selectedPrompt.step)) as number)
            }
            else if (!selectedPrompt && step) {
                setLastVersion(getLastVersion(data, step) as number)
            }
        }

    }, [selectedPrompt, data, step])

    return (
        <Grid container spacing={1}>
            <Grid xs={12} md={6}>
                <WeeklyOutlookPromptsForm setStep={setStep} step={step} isLoading={isLoading} data={selectedPrompt} setSelectedPrompt={setSelectedPrompt} lastVersion={lastVersion} submit={handleSubmit} />
            </Grid>
            <Grid xs={12} md={6}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    <Grid className='mb-1' container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabel className='mt-2' id="demo-multiple-name-label">- Status -</InputLabel>
                            <Select className='w-100' placeholder='Select Status' value={filteredStatus} onChange={(e) => setFilteredStatus(e.target.value)} >
                                {statusSelection.map((st, index) => (<MenuItem key={index} value={index - 1}>{st}</MenuItem>))}
                            </Select>
                        </Grid>

                        {
                            stepFilter.length > 0 && <Grid item xs={2}>
                                <InputLabel className='mt-2' id="demo-multiple-name-label">- Step -</InputLabel>
                                <Select className='w-100' placeholder='Select Step' value={selectedStep} onChange={(e) => setSelectedStep(e.target.value as string)} >
                                    {stepFilter.map((st, index) => (<MenuItem key={index} value={st}>{st}</MenuItem>))}
                                </Select>
                            </Grid>
                        }
                        {
                            archivePrompts.length > 0 && <Grid item xs={stepFilter.length ? 5 : 7}>
                                <FormControlLabel style={{ marginTop: '40px', float: "right" }} control={<Checkbox checked={showArchived} onChange={(e, checked) => setShowArchived(checked)} />} label="Show Archived" />
                            </Grid>
                        }

                    </Grid>

                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ width: '80px' }}>Version</th>
                                <th style={{ width: '80px' }}>Step</th>
                                <th>Notes</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th></th>
                            </tr>
                        </thead >
                        <tbody>
                            {prompts?.map((prompt, index: number) => (
                                <tr onClick={() => setSelectedPrompt(prompt)} style={{ cursor: 'pointer' }} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{prompt.version}</td>
                                    <td>{prompt.step}</td>
                                    <td>{prompt.notes}</td>
                                    <td style={{ color: prompt.status === 1 ? 'green' : prompt.status === 2 ? 'gray' : 'red' }}><b>{prompt.status === 1 ? 'Active' : prompt.status === 2 ? 'Archived' : 'Inactive'}</b></td>
                                    <td>{moment(prompt.date_created).format('MM/DD/YY h:mm A')}</td>
                                    <td>{moment(prompt.last_modified).format('MM/DD/YY h:mm A')}</td>
                                    <td>
                                        <tr>
                                            <td><RSButton tooltip={`${prompt.status === 0 ? 'Set As Active' : 'Restore'}`} disabled={prompt.status == 1 || isLoading} onClick={async (e: any) => {
                                                e.stopPropagation();
                                                if (prompt.status === 0) await activatePromptVersion(prompt?.id as number);
                                                else await restorePromptVersion(prompt?.id as number);
                                                setSelectedPrompt(undefined)
                                            }}>{
                                                    prompt.status === 0 || prompt.status === 1 ? <TaskAltOutlined /> : <RefreshOutlined />
                                                }</RSButton></td>
                                            <td><RSButton danger disabled={prompt.status === 2 || isLoading} tooltip='Archive Prompt' onClick={async (e: any) => {
                                                e.stopPropagation();
                                                await deletePromptVersion(prompt?.id as number);
                                                setSelectedPrompt(undefined)
                                            }} ><FaBoxArchive /></RSButton></td>
                                        </tr>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table >
                </div>
            </Grid>
        </Grid>
    )
}

export default WeeklyOutlookPrompts