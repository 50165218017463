import { FC, useState } from 'react'

import Grid from '@mui/material/Grid'
//import Box from '@mui/material/Box'

import AdditionalPlans from './home/AdditionalPlans'
import RunningPlan from './home/RunningPlan'
import Favorites from './home/FavoritesHome'
//import History from './home/HistoryHome'
import { AuthPageProps, RSTabsDataProps } from '../modules/types'
import WithAuth from '../components/WithAuth'
import RSNavCont from '../components/layout/RSNavCont'
import RSHeaderCont from '../components/layout/RSHeaderCont'
import useIsMobileScreen from '../hooks/useIsMobileScreen'
import useRSTabs from '../hooks/useRSTabs'

const Home: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const isMobile = useIsMobileScreen()
	const [runningLoading, setRunningLoading] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)

	const tabList: RSTabsDataProps = [{
		title: 'RUNNING',
		body: <RunningPlan userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} setIsEmpty={setIsEmpty}/>,
	}, {
		title: 'PROGRAMS',
		body: <AdditionalPlans userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />
	}, {
		title: 'FAVORITES',
		body: <Favorites userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />,
	}]

	const { tabsUI, tab } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
	})

	return (
		<RSNavCont loaderProps={{ active: !hasLoaded || runningLoading, isBehindHeader: false }} hideHeader={isMobile} contClass='background-bottom' contSX={{backgroundImage: isMobile ? `url(${isEmpty && !tab ? '/img-new/homepage/cover_images/runsmart_no_plan_half.jpg' : '/img-new/race-events/Runner-Group-Mobile.jpg'})` : ''}}>
			{isMobile && <RSHeaderCont title="Training"/>}
			<Grid xs={12} sx={{ px: 3, pt: 2, height: '100%', pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) 85%, transparent 100%)' }}>
				{tabsUI}
			</Grid>
		</RSNavCont>
	)
}

export default WithAuth(Home);
