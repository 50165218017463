import { FC, useState } from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { BarChart } from '@mui/x-charts/BarChart'
import { legendClasses } from '@mui/x-charts/ChartsLegend';

import ProvideFeedback from './ProvideFeedback'
import RSDrawer from '../../components/layout/RSDrawer'
import RSCardCont from '../../components/cards/RSCardCont'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import { RSButton1 } from '../../components/buttons'
import { H3, SH3, SH4, P1, P2 } from '../../components/text/RSTypography'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useStore from '../../store/useStore'
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore'

export type WeeklyOutlookProps = {
	showWeeklyOutlook: boolean,
	setShowWeeklyOutlook: any,
	weekDaysBody?: any,
	weekFirstDay?: any,
	weekLastDay?: any,
	weekPace?: number,
}

const WeeklyOutlook: FC<WeeklyOutlookProps> = ({ weekFirstDay, weekLastDay, weekDaysBody, weekPace, showWeeklyOutlook, setShowWeeklyOutlook }) => {
	const [open, setOpen] = useState(false)

	const isMobile = useIsMobileScreen()

	const { weeklyOutlook, setWeeklyOutlook } = useStore((state: IAiTrainingPlanStore) => state)

	const addPlural = (num: number, word: string) => num > 1 ? `${word}s` : word

	const renderBody = () => {

		//const summaryHeadLine = weeklyOutlook?.current?.headline
		const recap = weeklyOutlook?.current?.recap
		const unit = weeklyOutlook?.isKm ? 'kilometer' : 'mile'

		//const upcomingHeadline = weeklyOutlook?.upcoming?.headline
		const upcomingSplit = weeklyOutlook?.upcoming?.recap?.replaceAll('**', '')?.split("*")?.filter((x: string)=> x)
		const upcomingRecap = upcomingSplit?.[0]
		const upcomingBulletPoints = upcomingSplit?.splice(1)


		const defaultPaceInitialValue = 0
		const defaultPace = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.planned_pace) || 0),
			defaultPaceInitialValue,
		) || 0
		const defaultPaceHR = defaultPace > 59 ? Math.floor(defaultPace / 60) : 0
		const defaultPaceMIN = defaultPace > 59 ? defaultPace % 60 : Math.round(defaultPace)

		const completedPaceHR = 0
		const completedPaceMIN = 0

		const defaultInitialValue = 0
		const defaultDistance = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.planned_distance) || 0),
			defaultInitialValue,
		) || 0

		const completedInitialValue = 0
		const completedDistance = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.completed_distance) || 0),
			completedInitialValue,
		) || 0

		const upcomingDistance = weeklyOutlook?.upcomingDistance || 0
		const upcomingPace = weekPace || 0
		const upcomingPaceHR = upcomingPace > 59 ? Math.floor(upcomingPace / 60) : 0
		const upcomingPaceMIN = upcomingPace > 59 ? upcomingPace % 60 : upcomingPace



		console.log('///weeklyOutlook', defaultInitialValue, weeklyOutlook)

		return <Box>
			<RSHeaderCont secondary={!isMobile} isStatic={!isMobile} noShadow={!isMobile} backClick={() => setShowWeeklyOutlook(false)} title='Weekly Outlook' barSX={{backgroundColor: !isMobile ? 'transparent' : ''}} />
			<Grid xs={12} sx={{p: 3, pt: 1}}>
				{/*<Grid container>
					<SH2 size={1} sx={{mr: '5px'}}>Date Created:</SH2>
					<P1 size={1}>{moment(weeklyOutlook?.details?.date_created?.split('T')?.[0]).subtract(1, "days").format('MMMM DD, YYYY')}</P1>
				</Grid>*/}
				<H3 size={4} sx={{ width: '100%', mt: 2 }}>Last Week</H3>
				<P1 desc>{moment(weekFirstDay).subtract(7, 'd').format('M/D')}{weekLastDay ? moment(weekLastDay).subtract(7, 'd').format(' - M/D/YY') : ''}</P1>
				<RSCardCont className='weekly-bar-chart' sx={{mt: 1, px: 2, py: '13px'}}>
					<Grid container xs={12}>
						<SH3 title>Running</SH3>
						<Box sx={{ width: '100%', position: 'relative' }}>
							<BarChart
								margin={{ left: 20, right: 0, top: 45, bottom: 25 }}
								sx={{
									[`& .${legendClasses.mark}`]: {
										ry: '50%',
									}
								}}
								slotProps={{
									legend: {
										itemMarkWidth: 15,
										itemMarkHeight: 15,
										markGap: 5,
										itemGap: 20,
										padding: 0,
										position: {
											vertical: 'top',
											horizontal: 'left',
										}, labelStyle: {
											fontSize: '14px !important',
										},
									}
								}}
								dataset={weeklyOutlook?.currentGraphData || []}
								xAxis={[{ dataKey: 'day', scaleType: 'band', valueFormatter: (day: any) => day?.charAt(0) }]}
								series={[
									{
										dataKey: 'completed_distance',
										label: 'Results'
									},
									{
										dataKey: 'planned_distance',
										label: 'Plan'
									}
								]}
								colors={['#9fcd2b', '#0000003b']}
								height={270}
							/>
						</Box>
						<Grid container xs={12} sx={{ width: '100%', mt: 2, position: 'relative' }}>
							<Grid container xs={6} sx={{flexDirection: 'column'}} alignItems='center'>
								<SH4>Training Time</SH4>
								<Box sx={{ display: 'flex', mt: '2px'}}>
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<SH4 size={5}>{completedPaceHR}</SH4>
										<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedPaceHR, 'hr')}</P1>
									</Box>
									<Box sx={{ display: 'flex', alignItems: 'flex-end', ml: completedPaceHR ? '3px' : 0 }}>
										<SH4 size={5}>{completedPaceMIN}</SH4>
										<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedPaceMIN, 'min')}</P1>
									</Box>
								</Box>
								<P1 size={-2} className='gray-disabled-text'>
									{`Total Estimate:${defaultPaceHR ? ` ${defaultPaceHR} {addPlural(defaultPaceHR, 'hr')}` : ''}${defaultPaceMIN ? ` ${defaultPaceMIN} ${addPlural(defaultPaceMIN, 'min')}` : ''}`}
								</P1>
							</Grid>

							<Grid container xs={6} sx={{flexDirection: 'column'}} alignItems='center'>
								<SH4>Distance Ran</SH4>
								<Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '2px'}}>
									<SH4 size={5}>{completedDistance}</SH4>
									<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedDistance, unit)}</P1>
								</Box>
								<P1 size={-2} className='gray-disabled-text'>
									Total Estimate: {defaultDistance} {addPlural(defaultDistance, unit)}
								</P1>
							</Grid>
						</Grid>
					</Grid>
				</RSCardCont>
				<Grid container xs={12} sx={{mt: 2}}>
					{/*summaryHeadLine && <SH3 card>{summaryHeadLine}</SH3>*/}
					{recap && <P2 card>{recap}</P2>}
				</Grid>

				<H3 size={4} sx={{ width: '100%', mt: 2 }}>This Week</H3>
				<P1 desc>{moment(weekFirstDay).format('M/D')}{weekLastDay ? moment(weekLastDay).format(' - M/D/YY') : ''}</P1>
				<RSCardCont sx={{mt: 1, px: 2, pt: '5px', pb: 3, flexDirection: 'column'}}>
					<Grid container xs={12}>
						{weekDaysBody}
					</Grid>
					<Grid container xs={12} sx={{pt: 3}}>
						<Grid container xs={6} sx={{flexDirection: 'column'}} alignItems='center'>
							<SH4>Running</SH4>
							<Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '5px' }}>
								<SH4 size={5}>{upcomingDistance}</SH4>
								<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingDistance, unit)}</P1>
							</Box>
						</Grid>

						<Grid container xs={6} sx={{flexDirection: 'column'}} alignItems='center'>
							<SH4>Training Time</SH4>
							<Box sx={{ display: 'flex', mt: '4px' }}>
								{upcomingPaceHR ? <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<SH4 size={5}>{upcomingPaceHR}</SH4>
									<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingPaceHR, 'hr')}</P1>
								</Box> : <></>}
								{upcomingPaceMIN ? <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: upcomingPaceHR ? '3px' : 0 }}>
									<SH4 size={5}>{upcomingPaceMIN}</SH4>
									<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingPaceMIN, 'min')}</P1>
								</Box> : <></>}
							</Box>
						</Grid>
					</Grid>
				</RSCardCont>

				<Grid container xs={12} sx={{mt: 2}}>
					{/*upcomingHeadline && <SH3 card>{upcomingHeadline}</SH3>*/}
					<P2 card>{upcomingRecap}</P2>
					{upcomingBulletPoints?.map((ubp: any, ubpI: number) => <Grid container key={`ubp-${ubpI}`} xs={12} sx={{mt: 1}}>
						<i className="fa-regular fa-circle-arrow-right" style={{width: '20px', fontSize: '20px', marginRight: '5px', marginTop: '3px', height: '100%'}}/>
						<P2 card sx={{width: 'calc(100% - 25px)'}}>{ubp}</P2>
					</Grid>)}
				</Grid>


				<Grid container xs={12} sx={{pt: 10}} justifyContent='center' alignItems='center'>
					<P1 size={1} sx={{fontStyle: 'italic'}}>Was this helpful?</P1>
					<Grid container xs={12} justifyContent='center' alignItems='center'>
						<RSButton1 sx={{width: '100px', my: 2, mx: 1}} onClick={()=>setOpen(true)}>Yes</RSButton1>
						<RSButton1 secondary sx={{width: '100px', my: 2, mx: 1}}>No</RSButton1>
					</Grid>
				</Grid>
			</Grid>

			<RSDrawer
				bottom
				open={open}
				onClose={() => setOpen(false)}
				popUpWidth='auto'
				popUpHeight='auto'
				maxWidth='425px'
				fitContent
			>
				<ProvideFeedback setOpen={setOpen} weeklyOutlook={weeklyOutlook} setWeeklyOutlook={setWeeklyOutlook} />
			</RSDrawer>
		</Box>
	}
	console.log('///showWeeklyOutlook', isMobile, showWeeklyOutlook)
	return (isMobile ? <RSDrawer fullWidth open={showWeeklyOutlook} noClose={isMobile} onClose={()=> setShowWeeklyOutlook(false)}>
		{renderBody()}
	</RSDrawer> : (showWeeklyOutlook ? renderBody() : <></>))
}

export default WeeklyOutlook;
