/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { getHeight, getWeight} from '../../../../modules/miscUtils'
import RSButton from '../../../../components/buttons/RSButton'
import RSInput from '../../../../components/input/RSInput'
import RSInputAdornment from '../../../../components/input/RSInputAdornment'
import { SH2, P1, P2 } from '../../../../components/text/RSTypography'

export type NutritionInfoProps = {
    done?: any,
    bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const NutritionInfo: FC<NutritionInfoProps> = ({done, bodyData, setBodyData, nextAvailability}) => {
		const [feet, setFeet] = useState(bodyData?.height?.feet ? bodyData.height.feet : '');
		const [inch, setInch] = useState(bodyData?.height?.inch ? bodyData.height.inch : '');
		const [cm, setCm] = useState(bodyData?.height?.cm ? bodyData.height.cm : '');
		const [lbs, setLbs] = useState<any>(bodyData?.weight?.lbs ? bodyData.weight.lbs : '')
		const [kg, setKg] = useState<any>(bodyData?.weight?.kg ? bodyData.weight.kg : '')
		const [dietary, setDietary] = useState<any>(bodyData?.dietary ? bodyData.dietary : '')

		const renderBTN = (icon: any, text: string, action: any) => {
			return <RSButton sx={{mb: '10px'}} onClick={action}>
				{icon}
				<Box sx={{pl: '10px'}}>{text}</Box>
			</RSButton>
		}

		const renderWithLabel = (val: string, setVal: any, label: string, arial: string) => {
			return <RSInputAdornment value={val} setValue={setVal} label={label} arial={arial} />
		}

		useEffect(()=>{
			setBodyData({
				...bodyData,
				height: getHeight(bodyData?.isMetric, {feet, inch, cm}),
				weight: getWeight(bodyData?.isMetric, {kg, lbs}),
				dietary
			})
			nextAvailability((feet || inch || cm) && (kg || lbs))
		},[feet, inch, kg, lbs, dietary, cm])

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
    	<P1 sx={{ pb: 3, width: '100%' }}>
				We’ll use the information below for nutrition and hydration recommendations throughout training.
			</P1>

			<Grid container xs={12} sx={{pb: '25px'}} justifyContent='space-between' >
				<SH2 sx={{pb: '5px'}}>
					Height and Weight
				</SH2>
				<P2>
					You may update this information as you go.
				</P2>
				<Grid container xs={5.5} justifyContent='space-between'>
					{bodyData?.isMetric ?
						<Grid container xs={12}>
							{renderWithLabel(cm, setCm, 'CM', 'centimeters')}
						</Grid>
						: <>
						<Grid container xs={5.9}>
							{renderWithLabel(feet, setFeet, 'FT', 'feet')}
						</Grid>
						<Grid container xs={5.9}>
							{renderWithLabel(inch, setInch, 'IN', 'inch')}
						</Grid>
					</>}
				</Grid>
				<Grid container xs={5.5}>
					{renderWithLabel(bodyData?.isMetric ? kg : lbs, bodyData?.isMetric ? setKg : setLbs, bodyData?.isMetric ? 'KG' : 'LBS', 'weight')}
				</Grid>
			</Grid>

			<Grid container xs={12} sx={{pb: '15px'}} justifyContent='space-between' >
				<SH2 sx={{pt: '5px'}}>
					Do you have any specific dietary preferences?
				</SH2>
				<Grid container xs={12}>
					<RSInput value={dietary} setValue={setDietary} title={'gluten-free, vegan, etc'}/>
				</Grid>
			</Grid>

		</Box>
}

export default NutritionInfo
