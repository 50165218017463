/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { RSButton2 } from '../../../../components/buttons'
import { SH2 } from '../../../../components/text/RSTypography'

export type ProblemAreasInfoProps = {
    done?: any,
		bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const ProblemAreasInfo: FC<ProblemAreasInfoProps> = ({done, bodyData, setBodyData, nextAvailability}) => {
		const [problemAreas, setProblemAreas] = useState<Array<any>>(bodyData?.problemAreas?.length ? bodyData.problemAreas.map((pA: any)=>pA.index) : []);

		const activities = [{
			title: 'Feet / Ankles',
			img: <img src='./img-new/get-started/foot.svg' style={{height: '30px'}} />
		},{
			title: 'Achilles / Calves',
			img: <img src='./img-new/get-started/calf.svg' style={{height: '30px'}} />
		},{
			title: 'Knees',
			img: <img src='./img-new/get-started/knee.svg' style={{height: '30px'}} />
		},{
			title: 'Hamstrings',
			img: <img src='./img-new/get-started/hamstrings.svg' style={{height: '30px'}} />
		},{
			title: 'Quads',
			img: <img src='./img-new/get-started/quads.svg' style={{height: '30px'}} />
		},{
			title: 'Low Back',
			img: <img src='./img-new/get-started/lowback.svg' style={{height: '30px'}} />
		},{
			title: 'None',
			img: <i className="fa-light fa-ban" style={{fontSize: '40px'}}/>,
			removeAll: true
		}]

		useEffect(()=>{
			setBodyData({
				...bodyData,
				problemAreas: problemAreas?.map((pA:any)=>({index: pA, title: activities[pA]?.title}))
			})
			nextAvailability(problemAreas?.length)
		},[problemAreas])

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
			<Grid container xs={12} sx={{pb: '5px'}} justifyContent='space-between' >
				<SH2 sx={{pb: 2}}>
					Any problem areas?
				</SH2>
				<Grid container xs={12} justifyContent='space-between'>
					{activities.map((activity: any, ai: number)=>{
						const fndR = problemAreas.indexOf(ai)
						const isActive = fndR > -1
						const img = activity.img
						return <Grid item container xs={5.6} key={'activity-'+ai} sx={{height: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
							<RSButton2 secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}}
								onClick={()=>{
									const rs = [...problemAreas]
									!activity.removeAll && activities.forEach((a: any, aci: number) => {
										if (a.removeAll) {
											const fndR2 = rs.indexOf(aci)
											fndR2 > -1 && rs.splice(fndR2, 1)
										}
									})
									if (isActive) {
										rs.splice(fndR, 1);
										setProblemAreas(rs)
									} else
										activity.removeAll ? setProblemAreas([ai]) : setProblemAreas([...rs, ai])
								}}>
								{img && <Box sx={{mb: '10px'}}>{img}</Box>}
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px', mb: img ? '-10px' : 0}}>{activity.title}</Box>
							</RSButton2>
						</Grid>
					})}
				</Grid>
			</Grid>
		</Box>
}

export default ProblemAreasInfo
