import { FC } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button';

import RSIcon from '../icons/RSIcon'

export type RSButtonProps = {
	onClick?: any,
	className?: string,
	id?: string,
	sx?: object,
	disabled?: boolean,
	children?: any,
	tertiary?: boolean,
	secondary?: boolean,
	ghost?: boolean,
	borderless?: boolean,
	selected?: boolean,
	input?: boolean,
	inputSX?: object,
	href?: string,
	noArrow?: boolean,
	impose?: boolean,
}


export const RSButton1: FC<RSButtonProps> = ({ onClick, className = '', id = '', sx = {}, disabled, children, tertiary, secondary, input, inputSX = {}, href, noArrow, impose }) => {
	const getClassName = () => {
		if (disabled)
			return 'gray-disabled-bg gray-disabled-text'
		else if (secondary)
			return 'default-bs white-bg white-bg-active'
		else if (tertiary)
			return 'blue-text white-bg-active'
		else if (input)
			return 'gray-input-border white-bg'
		else
			return 'primary-bg'
	}

	const handleClick = (e: any) => {
		onClick?.(e)
	}

	return <Button {...href && {href}} className={`rsButtonStyle${impose ? ' imposed ' : ' '}${getClassName()} ${className}`} id={id} sx={{p: '10px', ...sx}} disabled={disabled} onClick={handleClick}>
		{input ? <Box sx={{ lineHeight: '1em', width: 'auto', pl: 1, pr: '5px', overflow: 'hidden', textAlign: 'left', ...inputSX }}>
			{children}
		</Box> : children}
		{input && !noArrow && <RSIcon type='caret-down' height='20px' width='30px' />}
	</Button>
}

export const RSButton2: FC<RSButtonProps> = ({ onClick, className = '', id = '', sx = {}, disabled, children, tertiary, secondary, ghost, borderless, selected, href, impose }) => {
	const getClassName = () => {
		const bs = onClick ? 'default-bs' : ''

		if (disabled)
			return `${selected ? 'orange-bg opacity-half' : 'gray-disabled-bg gray-disabled-text'}`
		else if (secondary)
			return `${bs} white-bg white-bg-active`
		else if (tertiary)
			return `${bs} gray-mid-bg white-bg-active`
		else if (ghost)
			return 'gray-dark-border white-bg white-bg-active'
		else
			return 'orange-bg orange-border'
	}

	const handleClick = (e: any) => {
		onClick?.(e)
	}

	return <Button {...href && {href}} className={`rsButtonStyle${impose ? ' imposed ' : ' '}${getClassName()} ${className} ${borderless ? 'borderless' : ''}`} id={id} sx={{p: '10px', ...sx}} disabled={disabled || !onClick} onClick={handleClick}>
		{children}
	</Button>
}
