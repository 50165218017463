import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import useSWR from 'swr'
import is_numeric from 'locutus/php/var/is_numeric'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import WeeklyOutlook from './WeeklyOutlook'
import { DAYS, DAYS_ALT } from '../training-plan/build/DaysOptions';
import { UserInfoProps } from '../../modules/types'
//import { MISC_LOGOS } from '../../modules/miscImages'
import { titleCase, getColorCode } from '../../modules/miscUtils'
import useAppStorage from '../../hooks/useAppStorage'
import useHomePage from '../../hooks/useHomePage'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import RSCardCont from '../../components/cards/RSCardCont'
import { RSButton1 } from '../../components/buttons'
import { H3, SH4, P1, P2 } from '../../components/text/RSTypography'
import useWeeklyOutlook from '../../api/v2/weekly-outlook/useWeeklyOutlook'
import { GetTrainingPlanInfo } from '../../modules/aiTrainingPlanActions'
import useGPTTrainingPlan from '../race/useGPTTrainingPlan'
import useStore from '../../store/useStore'
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore'

export type RunningPlanProps = UserInfoProps & {
	hasLoaded: boolean | undefined,
	mainLoading?: boolean,
	setMainLoading?: any,
	setIsEmpty?: any,
}

const RunningPlan: FC<RunningPlanProps> = ({ userInfo, hasLoaded, setMainLoading, mainLoading, setIsEmpty }) => {
	const { data: accountProfile } = useSWR(userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })
	const navigate = useNavigate()
	const [userInfoNew, setUserInfo] = useState<any>(null)
	const [data, setData] = useState<any>({})
	//const [logoLoading, setLogoLoading] = useState(true)
	const [showWeeklyOutlook, setShowWeeklyOutlook] = useState(false)
	const [weeklyLoading, setWeeklyLoading] = useState(true)
	const { isIos } = useAppStorage()
	const { infoRow, isInfoLoading } = useHomePage({ isIos: isIos || false, userInfo: userInfoNew || userInfo })
	const isMobile = useIsMobileScreen()

	const [trainingPlanInfo, setTrainingPlanInfo] = useState<any>(null)
	const { getWeeklyOutlookDetails, getWeeklyOutlook } = useWeeklyOutlook(trainingPlanInfo)
	const { setWeeklyOutlook, weeklyOutlook } = useStore((state: IAiTrainingPlanStore) => state)
	const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()


	const noRaceDay = data?.typeIndex == 4 || data?.typeIndex == 7 ? true : false
	const raceDate = moment(data?.otherInfo).format('YYYY/MM/DD')

	const weekFirstDay = moment(data?.curr_date).subtract(data?.currentDay || 0, 'days').format('YYYY/MM/DD')
	let weekLastDay = moment(data?.curr_date).add(6 - (data?.currentDay || 0), 'days').format('YYYY/MM/DD')
	weekLastDay = moment(weekLastDay).isAfter(moment(raceDate)) ? raceDate : weekLastDay
	weekLastDay = weekLastDay !== weekFirstDay ? weekLastDay : ''

	const suggestedStartDate = data?.currentDay === 0 ? moment(data?.curr_date) : moment(weekFirstDay).add(7, 'days')


	const getWeekly2 = async (res: any, convertedInfo: any, infoDataCurrentWeek: any) => {
		await getWeeklyOutlook(res?.user_id, infoDataCurrentWeek, res?.is_km, res, convertedInfo).then((res1: any) => {
			setWeeklyOutlook(res1)
			setWeeklyLoading(false)
		})
	}

	const getWeekly = async (allTrainingData: any, convertedInfo: any, infoDataCurrentWeek: any) => {
		await getWeeklyOutlookDetails(allTrainingData, infoDataCurrentWeek).then((res: any) => {
			if (!(res && res?.user_id && infoDataCurrentWeek)) {
				setWeeklyLoading(false)
				return
			}
			getWeekly2(res, convertedInfo, infoDataCurrentWeek)
		})
	}

	useEffect(() => {
		setMainLoading?.(data?.scheduleText === undefined ? true : false)
		setIsEmpty?.(data?.scheduleText ? false : true)
	}, [data?.scheduleText])


	useEffect(() => {
		const userAccountData = accountProfile?.data?.result?.[0];
		if (userAccountData?.user_id) {
			const newUserInfo = { ...userInfo, km: userAccountData?.measurement_system === "metric" ? "Y" : "N" }
			setUserInfo(newUserInfo)
		}
	}, [accountProfile])


	useEffect(() => {
		const abortController = new AbortController()

		if (infoRow?.length) {
			const infoData = infoRow[0]
			const { id, link, text, daysUntilRace = 0, daysUntilStart = 0, trainingType, scheduleText, planType } = infoData
			let redirectLink = id && link && scheduleText ? `${link?.replace('/#', '')}?planId=${id}` : '/explore'
			if (planType === 'GPT') {
				redirectLink = redirectLink.replace('training-plan', 'training-plan-v3')
			}

			const newText = text?.split('\n')
			const trainingTypeFormatted = !trainingType
				? '' : trainingType[trainingType.length - 1] == 'k'
					? trainingType.toUpperCase()
					: titleCase(trainingType)
			const trainingText = trainingType ? `${trainingTypeFormatted} ${trainingType.toLowerCase().includes('recovery') ? '' : ' training'}` : ''
			const daysUntilRaceText = `${daysUntilRace} day${daysUntilRace > 1 ? 's' : ''}`
			const daysUntilStartText = `${daysUntilStart} day${daysUntilStart > 1 ? 's' : ''}`
			setData({ ...infoData, trainingText, daysUntilRaceText, daysUntilStartText, link: redirectLink, text: newText })
			if (id)
				GetTrainingPlanInfo(id, abortController.signal).then(async (res: any) => {
					const allTrainingData = res?.data?.result[0]
					const convertedInfo = convertToLegacyTrainingInfo(allTrainingData)
					setTrainingPlanInfo({
						convertedInfo: convertedInfo,
						allTrainingData: allTrainingData,
					})
				})
		}
	}, [infoRow])

	useEffect(() => {
		if (!(infoRow.length && trainingPlanInfo?.convertedInfo && trainingPlanInfo?.allTrainingData)) {
			console.log('>>> no infoRow or trainingPlanInfo')
			return
		}
		const infoData = infoRow[0]
		// const currentWeek = 10
		// infoData.currentWeek = currentWeek
		if (!infoData?.currentWeek) {
			console.log('>>> no currentWeek')
			return
		}

		setWeeklyLoading(true)
		getWeekly(trainingPlanInfo?.allTrainingData, trainingPlanInfo?.convertedInfo, infoData?.currentWeek)

	}, [trainingPlanInfo, infoRow])

	const renderWeekCalendar = (weekOnly?: boolean) => {
		const weeksDefault = data?.monday_start ? DAYS_ALT : DAYS
		const allTrainingText = data?.all_training_text
		return allTrainingText?.length > 0 ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 1 }}>
			{allTrainingText.map((ttData: any, ttIndex: number) => {
				const cc = getColorCode(ttData)
				const actUpper = ttData?.toUpperCase()
				const distanceArr = ttData?.split('|')?.map((v: any) => v.trim())?.[0]?.split(' ')
				const isThisNumber = distanceArr?.length ? is_numeric(distanceArr[0]) : false
				const isThisRP = actUpper?.includes('RACE PACE')
				const isThisRest = actUpper?.includes('REST')
				const isThisResult = actUpper?.includes('RESULT') || actUpper?.includes('RACE DAY')
				const isThisCT = actUpper?.includes('CROSS TRAIN')
				const watchThisRecovery = actUpper?.includes('RECOVERY VIDEOS')
				const isThisAssessment = actUpper?.includes('ASSESSMENT')
				const isWhite = isThisRest || watchThisRecovery || isThisAssessment
				const isAfterRD = moment(weekFirstDay).add(ttIndex, 'd').isAfter(moment(raceDate))
				const isCurrentDay = data?.currentDay === ttIndex && !weekOnly

				let dataDisplay = ''
				if (isAfterRD && !noRaceDay)
					dataDisplay = ''
				else if (isThisNumber)
					dataDisplay = distanceArr[0]
				else if (isThisCT)
					dataDisplay = 'XT'
				else if (isThisRP)
					dataDisplay = 'RP'
				else if (isThisRest || watchThisRecovery)
					dataDisplay = 'R'
				else if (isThisAssessment)
					dataDisplay = 'A'
				else if (isThisResult)
					dataDisplay = 'RD'
				else
					dataDisplay = actUpper?.charAt(0)

				return <Box key={ttIndex + 'all-text'} className={dataDisplay ? '' : 'gray-disabled-bg'} sx={{ opacity: dataDisplay ? 1 : 0.4, width: `calc((100% / ${allTrainingText.length}) - 2px)`, mx: '1px', mb: '1px', height: '35px', borderRadius: '4px', backgroundColor: isWhite ? '#F6F5F5' : cc, overflow: 'hidden' }}>
					<P2 size={-2} className={isCurrentDay ? 'danger-bg' : ''} sx={{ py: '1px', color: isCurrentDay ? '#fff' : '#010101', textAlign: 'center' }}>{weeksDefault[ttIndex]?.day?.charAt(0)}</P2>
					<P1 size={1} sx={{ pt: '2.5px', textAlign: 'center' }}>{dataDisplay}</P1>
				</Box>
			})}
		</Box> : <></>
	}

	const renderBody = () => {
		const color = getColorCode(data?.scheduleText)
		const activityArr = data?.scheduleText?.split('|')?.map((v: any) => v.trim())
		const mileArr = activityArr?.[0]?.split(' ')
		const isNumber = mileArr ? is_numeric(mileArr[0]) : false

		const pace = data?.pace || 0
		const hr = pace > 59 ? Math.floor(pace / 60) : 0
		const min = pace > 59 ? pace % 60 : pace
		const hasStarted = data?.daysUntilStart < 1 ? true : false
		const act = (activityArr?.[1] || activityArr?.[0])?.toUpperCase()
		const isRP = act?.includes('RACE PACE')
		const isRest = act?.includes('REST')
		const isResult = act?.includes('RESULT')
		const isCT = act?.includes('CROSS TRAIN')
		const watchRecovery = act?.includes('RECOVERY VIDEOS')
		const isAssessment = act?.includes('ASSESSMENT')
		const isTrainingNotStarted = moment(data?.trainingStartDate).isAfter(moment(data?.curr_date))
		const moreDetails = !isTrainingNotStarted && (isCT || isResult || watchRecovery || isAssessment || isRP) ? true : false
		const hasMiles = !isCT && !isResult && !watchRecovery && !isAssessment && !isRP

		const crossTrainActivities = activityArr?.[2]?.split(',')
		const allTrainingText = data?.all_training_text
		const totalWeekDistance = allTrainingText?.reduce((acc: number, curr: any) => {
			const x = curr?.split('|')?.map((v: any) => v?.trim())?.[0]?.split(' ')?.[0]
			return acc + (is_numeric(x) ? Number(x) : 0)
		}, 0)
		let unit = accountProfile?.data?.result?.[0]?.measurement_system?.toLowerCase() === 'imperial' ? 'mile' : (window.innerWidth > 370 ? 'kilometer' : 'km')
		unit += totalWeekDistance > 1 && unit !== 'km' ? 's' : ''

		return <Box sx={{ width: '100%', pt: 2, minHeight: '80px', flexDirection: 'column', position: 'relative', display:  `${showWeeklyOutlook && !isMobile ? 'none' : 'block'}` }}>
			{!hasLoaded || isInfoLoading || data?.scheduleText === undefined ?
				<Box sx={{ maxWidth: '450px', mt: 1 }} className='loading-days toRight borderless default-bs' />
				: (data?.scheduleText ? <>
					<Box sx={{ width: '100%', mt: 1, alignItems: 'center' }} >
						{/*<Box sx={{  height: '80px', width: '80px', mx: 'auto', position: 'relative' }} justifyContent='center' alignItems='flex-start'>
							{logoLoading && <Box className='loading-days toRight' sx={{ border: 'none !important', position: 'absolute', height: 'calc(100% + 1px)', width: 'calc(100% + 1px)', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} /> }
							<img onLoad={()=> setLogoLoading(false)} src={data?.race_logo} style={{ borderRadius: '10px', height: '100%', width: '100%' }} onError={onErrorLoadImg()}/>
						</Box>*/}
						<Box sx={{ width: '100%' }}>
							<SH4 className='ellipsis-2l' size={5} sx={{ textAlign: 'left' }}>{data?.event_name || data?.trainingText}</SH4>
							{!noRaceDay && data?.otherInfo ? <Box sx={{ width: '100%', display: 'flex', pt: '5px' }}>
								<P1 sx={{ width: 'auto', mr: '5px' }}>
									Race Date:
								</P1>
								<P2 sx={{ width: 'auto' }}>
									{moment(raceDate).format('ddd, MMM DD, YYYY')}
								</P2>
							</Box> : <></>}
							<Box sx={{ width: '100%', display: 'flex', pt: '3px' }}>
								<P1 sx={{ width: 'auto', mr: '5px' }}>
									{hasStarted ? 'Remaining' : 'Starts in'}:
								</P1>
								<P2 sx={{ width: 'auto' }}>
									{hasStarted ? data?.daysUntilRaceText : data?.daysUntilStartText}
								</P2>
							</Box>
						</Box>
					</Box>
					<Grid container xs={12} alignItems='flex-start'>
						<Grid container xs={isMobile ? 12 : 6} sx={{ mt: 2, pr: isMobile ? 0 : 1 }}>
							{hasStarted && <H3 sx={{ width: '100%' }}>TODAY</H3>}
							{hasStarted && <RSCardCont sx={{ width: '100%', maxWidth: '450px', mt: 1, px: 2, py: '13px', flexDirection: 'column', minHeight: 0, justifyContent: 'flex-start' }} >
								<Grid container sx={{ width: '100%' }}>
									<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
										<Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
											<P1 sx={{ width: 'auto', mr: '5px', lineHeight: '1.2em !important' }}>
												Date:
											</P1>
											<P2 sx={{ width: 'auto', lineHeight: '1.2em !important' }}>
												{moment(data?.curr_date).format('ddd, MMM DD, YYYY')}
											</P2>
										</Box>
										<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', pt: '3px' }}>
											{!isResult && <P1 sx={{ width: 'auto', mr: '5px', lineHeight: '1.2em !important' }}>
												Type:
											</P1>}
											{!isRest && !isResult && <Box sx={{ lineHeight: '1.2em', mx: '5px', height: '12px', width: '12px', borderRadius: '50%', backgroundColor: color }} />}
											<P2 sx={{ width: 'auto', textTransform: 'capitalize !important', lineHeight: '1.2em !important' }}>
												{act}
											</P2>
										</Box>
									</Box>
									{crossTrainActivities?.length > 0 && <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
										<P1 sx={{ width: 'auto', mr: '5px', lineHeight: '1.2em !important' }}>
											Crosstrain:
										</P1>
										<P2 sx={{ width: 'auto', textTransform: 'capitalize !important', lineHeight: '1.2em !important' }}>
											{crossTrainActivities?.join(', ')}
										</P2>
									</Box>}
									{!data?.text?.[0]?.includes('begins') && data?.daysUntilStart > 0 ? <></> : (
										!isRest && <Box sx={{ display: 'flex', justifyContent: 'center', height: isTrainingNotStarted ? 'auto' : '50px', mt: 2 }}>
											{hasMiles && mileArr?.length > 0 && <Box sx={{ px: '15px' }}>
												<SH4 size={6}>{(isNumber ? Number(mileArr[0]).toFixed(1) : mileArr.join(' '))}</SH4>
												{mileArr?.length > 1 && isNumber && <Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>{titleCase(mileArr[1])}</Box>}
											</Box>}
											{moreDetails ? <Grid container sx={{ px: '15px', margin: hasMiles ? 0 : 'auto' }}>
												<Box sx={{ fontSize: '18px', fontFamily: 'Poppins' }}>{isAssessment ? 'Begin Assessment' : (isResult ? 'Enter Result' : (watchRecovery ? 'Watch Videos' : `View ${isCT ? 'More' : 'Pace'} Details`))}</Box>
											</Grid>
												: ((hr || min) ? <Box sx={{ px: '15px' }}>
													<Box sx={{ display: 'flex' }}>
														{hr ? <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
															<SH4 size={6}>{hr}</SH4>
															<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>hr{hr > 1 ? 's' : ''}</P1>
														</Box> : <></>}
														{min ? <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: hr ? '3px' : 0 }}>
															<SH4 size={6}>{min}</SH4>
															<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>min{min > 1 ? 's' : ''}</P1>
														</Box> : <></>}
													</Box>
													<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Est. Duration</Box>
												</Box> : <></>)
											}
										</Box>
									)}
								</Grid>
								<Grid container sx={{ mt: 2 }} justifyContent='center'>
									<RSButton1 impose sx={{ zIndex: 1, width: '150px' }} onClick={data?.link ? () => navigate(data?.link, { replace: true }) : undefined}>
										View Plan
									</RSButton1>
								</Grid>
							</RSCardCont>}

							{!hasStarted && moment(data?.trainingStartDate).diff(moment(suggestedStartDate), 'weeks') > 1  && <RSCardCont sx={{ width: '100%', maxWidth: '450px', mt: 1, px: 2, py: '13px', flexDirection: 'column', minHeight: '300px', justifyContent: 'center' }} >
								<Grid container sx={{ flexDirection: 'column', width: '100%' }} justifyContent='center' alignItems='center'>
									<H3 size={5} card sx={{ width: '100%', textAlign: 'center' }}>Stay on Track</H3>
									<P1 size={1} card sx={{ width: '100%', mt: 1, textAlign: 'center' }}>
										{`Bridge the gap to the start of the Buffalo Marathon Weekend training with a maintenance running plan from ${moment(suggestedStartDate).format('MMMM D')} to ${moment(data?.trainingStartDate).subtract(1, 'd').format('MMMM D')}`}
									</P1>
								</Grid>
								<Grid container sx={{ mt: 5 }} justifyContent='center'>
									<RSButton1 impose sx={{ zIndex: 1, width: '150px' }}
										onClick={(e: any) => {
												e.stopPropagation()
												navigate(`/explore?race_type=4&end=${moment(data?.trainingStartDate).subtract(1, 'd').format('YYYY/MM/DD')}`, { replace: true })
											}
										}>Get Started</RSButton1>
								</Grid>
							</RSCardCont>}
						</Grid>
						{!isResult && hasStarted && <Grid container xs={isMobile ? 12 : 6} sx={{ mt: 2, pl: isMobile ? 0 : 1 }}>
							<H3 sx={{ width: '100%' }}>WEEKLY OUTLOOK</H3>
							<RSCardCont sx={{ width: '100%', maxWidth: '450px', mt: 1, px: 2, py: '13px', flexDirection: 'column' }} >
								{/*<Box sx={{ position: 'absolute', right: 16, top: 16, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '50px' }}>
									<i className="fa-solid fa-chevron-right" style={{ fontSize: '22px'}} />
								</Box>*/}
								<Grid container sx={{ width: '100%' }}>
									<Grid container xs={12} sx={{ position: 'relative' }}>
										<Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
											<P1 sx={{ width: 'auto', mr: '5px', lineHeight: '1.2em !important' }}>
												Date Range:
											</P1>
											<P2 sx={{ width: 'auto', lineHeight: '1.2em !important' }}>
												{moment(weekFirstDay).format('MMM DD')}{weekLastDay ? moment(weekLastDay).format(' - MMM DD') : ''}
											</P2>
										</Box>
										<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', pt: '3px' }}>
											<P1 sx={{ width: 'auto', mr: '5px', lineHeight: '1.2em !important' }}>
												Total Distance:
											</P1>
											<P2 sx={{ width: 'auto', lineHeight: '1.2em !important' }}>
												{data?.daysUntilRace < 8 && !noRaceDay ? 'See details' : `${totalWeekDistance} ${unit}`}
											</P2>
										</Box>
									</Grid>
									{renderWeekCalendar()}

									{weeklyLoading ? <Box sx={{ width: '100%', height: '100px',position: 'relative', display: 'flex', alignItems: 'center', mt: 1 }}>
											<Box sx={{ height: '100%' }} className='loading-days toRight borderless default-bs' />
										</Box> : (weeklyOutlook && weeklyOutlook?.current && <>
											<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', pt: 1 }}>
												<P2 sx={{ width: 'auto', lineHeight: '1.2em !important', textAlign: 'left' }}>
													<span className='lh-1-2' style={{ fontSize: '14px', fontFamily: 'Poppins' }}>Summary: </span>
													<P1 sx={{ width: 'auto', mr: '5px', mb: '10px' }}>
														{weeklyOutlook?.current?.headline}
													</P1>
													<P2 className='multi-line-ellipsis' sx={{ width: 'auto' }}>
														{weeklyOutlook?.current?.recap}
													</P2>
												</P2>
											</Box>
											<Grid container xs={12} sx={{ mt: 2 }} justifyContent='center'>
												<RSButton1 impose onClick={(e: any) => {
													e.stopPropagation()
													setShowWeeklyOutlook(true)
													//navigate('/weekly-outlook', { replace: true })
												}} sx={{ zIndex: 1, width: '150px' }}>View Outlook</RSButton1>
											</Grid>
										</>)
									}
								</Grid>
							</RSCardCont>
						</Grid>}
					</Grid>
				</> : <RSCardCont sx={{ width: '100%', minHeight: '400px', mt: 1, p: 3, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					<Grid container sx={{ flexDirection: 'column', width: '100%' }} justifyContent='flex-start' alignItems='flex-start'>
						{(data?.text?.length ? data.text.map((text: string, ti: number) =>
							!ti ? <H3 size={5} card sx={{ width: '100%', textAlign: 'center' }}>{text}</H3> : <P1 size={1} card sx={{ width: '100%', mt: 1, textAlign: 'center' }}>{text}</P1>) : <></>)}
					</Grid>
					<RSButton1 impose sx={{ width: '200px', mt: 5 }}
						onClick={(e: any) => {
							e.stopPropagation()
							navigate('/explore', { replace: true })
						}
						}
					>Get Started</RSButton1>
				</RSCardCont>)
			}
		</Box>
	}

	return <Box>
		<Grid container xs={12}>
			{!mainLoading && renderBody()}
			<WeeklyOutlook weekFirstDay={weekFirstDay} weekLastDay={weekLastDay} weekPace={data?.weekPace || 0} weekDaysBody={renderWeekCalendar(true)} showWeeklyOutlook={showWeeklyOutlook} setShowWeeklyOutlook={setShowWeeklyOutlook}/>
		</Grid>
	</Box>
}

export default RunningPlan;
