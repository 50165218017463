/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RSButton1, RSButton2 } from '../../components/buttons'
import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import { H1, SH2, P1 } from '../../components/text/RSTypography'
import RSDrawer from '../../components/layout/RSDrawer';
import RSIcon from '../../components/icons/RSIcon'
import useRSRunAIsuggestions from '../../hooks/useRSRunAIsuggestions';
import Lottie from 'lottie-react';
import RunningLoader from '../../components/animation/runsmart_loader.json'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';

export type PlanPacingProps = {
	addDetails: any
	allStageDetails: any,
	handleIsStepComplete?: any
	setMainLoading: any
	raceDetails: any
}

const PlanPacing: FC<PlanPacingProps> = ({ addDetails, allStageDetails, handleIsStepComplete, setMainLoading, raceDetails }) => {

	const stageNumber = '5'
	const { goalTime: gl, goalCasualTime: gct, runPace } = allStageDetails?.[stageNumber] || {}
	const [imNotSure, setImNotSure] = useState(false)

	const { ConvertFullToHalf, ConvertH2M, CalcEPace, CalcvDOT, ConvertM2Mt, ConvertM2H2 } = useTrainingPlanUtils()
	const [seconds, setSeconds] = useState<any>(gl?.seconds || '')
	const [minutes, setMinutes] = useState<any>(gl?.minutes || '')
	const [hours, setHours] = useState<any>(gl?.hours || '')

	const [dSeconds, setDSeconds] = useState<any>('')
	const [dMinutes, setDMinutes] = useState<any>('')
	const [dHours, setDHours] = useState<any>('')
	const [isLoading, setIsLoading] = useState(false)

	const [casualSeconds, setCasualSeconds] = useState<any>(gct?.seconds || '')
	const [casualMinutes, setCasualMinutes] = useState<any>(gct?.minutes || '')

	const { getSuggestedGoalTime } = useRSRunAIsuggestions()

	const isMaintenancePlan = raceDetails?.selectedRaceDistance.toLowerCase().includes('maintenance')

	const activities = ['5K', '10K', '15K', 'Half Marathon', 'Full Marathon', 'Casual Pace']

	const [option, setOption] = useState(isMaintenancePlan && (gct?.minutes || gct?.seconds) ? 'Casual Pace' : (runPace ? runPace : 'Half Marathon'))

	const isCasualPace = option === 'Casual Pace' && isMaintenancePlan

	const mergedStageDetails = {
		...allStageDetails["1-0"], ...allStageDetails["1-1"], ...allStageDetails["1-2"],
		...allStageDetails["2"], ...allStageDetails["3"], ...allStageDetails["4"], runPace: option
	}

	const removeHours = mergedStageDetails?.raceType?.toLowerCase() === '5k' || (isMaintenancePlan && (option === '5K' || option === 'Casual Pace'))
	const getSuggestedGoalTimeAI = async () => {
		setMainLoading(true)
		setIsLoading(true)

		// console.log('>>> mergedStageDetails: ', mergedStageDetails)
		const goalTime = await getSuggestedGoalTime(mergedStageDetails)
		// console.log('>>> goalTime: ', goalTime)

		setSeconds(goalTime?.seconds)
		setMinutes(goalTime?.minutes)
		setHours(goalTime?.hours)

		goalTime && setMainLoading(false)
		goalTime && setIsLoading(false)
	}

	const getRaceDistance = (raceType: string) => {
		const halfMILES = 13.1094
		const fullMILES = 26.2188
		const fiveK = 3.1068
		const tenK = 6.2137
		const eightK = 4.97097
		const tenMiles = 10

		switch (raceType) {
			case '5k':
				return fiveK
			case '10k':
				return tenK
			case '8k':
				return eightK
			case 'half':
				return halfMILES
			case 'full':
				return fullMILES
			case 'maintenance':
				return fullMILES
			case '10 mile':
				return tenMiles
		}
	}

	const getCalculatedGoalTime = () => {
		let timeM = 0
		const halfMILES = 13.1094
		const fullMILES = 26.2188

		const raceDistance = getRaceDistance(allStageDetails?.['1-0']?.raceType?.toLowerCase()) as number || 0
		if (option === 'Full Marathon') timeM = getConvertedTimeM(raceDistance, fullMILES, 1.06)
		else if (option === '5K') timeM = getConvertedTimeM(raceDistance, 3.1068, 1.06)
		else if (option === '8K') timeM = getConvertedTimeM(raceDistance, 4.97097, 1.06)
		else if (option === '10K') timeM = getConvertedTimeM(raceDistance, 6.2137, 1.06)
		else if (option === '15K') timeM = getConvertedTimeM(raceDistance, 9.3206, 1.06)
		else if (option === 'Half Marathon') timeM = getConvertedTimeM(raceDistance, halfMILES, 1.06)
		else if (option === 'Casual Pace') timeM = getCalculatedGoalTimeByPace(raceDistance)
		// else if (option === 'Half Marathon') timeM = getConvertedTimeMv2(halfMILES)

		// if (option === 'Full Marathon') timeM = getConvertedTimeM(halfMILES, fullMILES, 1.06)
		// else if (option === '5K') timeM = getConvertedTimeM(halfMILES, 3.1068, 1.06)
		// else if (option === '10K') timeM = getConvertedTimeM(halfMILES, 6.2137, 1.06)
		// else if (option === '15K') timeM = getConvertedTimeM(halfMILES, 9.3206, 1.06)
		// else if (option === 'Half Marathon') timeM = getConvertedTimeM(fullMILES, halfMILES, 1.06)
		// // else if (option === 'Half Marathon') timeM = getConvertedTimeMv2(halfMILES)
		// else if (option === 'Casual Pace') timeM = getCalculatedGoalTimeByPace(halfMILES)

		setHours(Math.floor(timeM / 60))
		setMinutes(Math.floor(timeM % 60))
		setSeconds(Math.floor((timeM * 60) % 60))
	}

	const getConvertedTimeM = (raceDistance: number, optionDistance: number, eff: number) => {

		const goalTime = Number(dMinutes) + Number(dHours * 60) + Number(dSeconds / 60)
		// console.log('goalTime', goalTime)
		return goalTime * Math.pow((raceDistance / optionDistance), eff)

	}


	const getConvertedTimeMv2 = (raceDistance: number) => {
		const halfMILES = 13.1094
		const fullMILES = 26.2188

		const goalTime = Number(dMinutes) + Number(dHours * 60) + Number(dSeconds / 60)
		const timeM = goalTime * Math.pow((fullMILES / halfMILES), 1.06)

		const distanceMt = ConvertM2Mt(halfMILES)
		const vDOT = Number(CalcvDOT(distanceMt, timeM))
		// console.log('vDOT', vDOT)
		const pace = CalcBPace(raceDistance, vDOT) * 0.95
		// console.log('pace', pace)
		const convertedTime = pace * raceDistance
		return convertedTime
	}


	const CalcBPace = (dist: number, vdot: number) => {
		let pace = 0.915 * (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 0.67 * vdot)));
		dist = dist * 0.000621371;
		pace = pace / dist;
		// if (isKm) {
		//     pace = computeKmPace(pace);
		// }
		return pace
	}

	const CalcRPace = (dist: number, vdot: number) => {
		let pace = (dist * 2 * 0.000104) / (-0.182258 + Math.sqrt(Math.pow(0.182258, 2) - 4 * 0.000104 * (-4.6 - 1.08 * vdot)));
		dist = dist * 0.000621371;
		pace = pace / dist;
		// if (isKm) {
		//     pace = computeKmPace(pace);
		// }
		return pace
	}


	const getCalculatedGoalTimeByPace = (raceDistance: number, min?: any, sec?: any) => {
		const pace = Number(min || dMinutes) + Number(dHours * 60) + Number((sec || dSeconds) / 60)
		const newPace = pace * 0.9
		return newPace * raceDistance
	}

	const handleChangeOption = (activity: string) => {
		setOption(activity)
		if (isMaintenancePlan) {
			setHours('')
			setMinutes('')
			setSeconds('')
			setCasualSeconds('')
			setCasualMinutes('')
			setImNotSure(false)
		}
	}

	useEffect(() => {
		addDetails(stageNumber,
			{
				goalTime: { seconds, minutes, hours },
				goalCasualTime: { seconds: casualSeconds, minutes: casualMinutes },
				runPace: isCasualPace ? 'Half Marathon' : option,
				casualPace: isCasualPace
			})
		handleIsStepComplete(seconds || minutes || hours ? true : false, stageNumber)
	}, [seconds, minutes, hours])

	useEffect(() => {
		if (!imNotSure) {
			setDSeconds('')
			setDMinutes('')
			setDHours('')
			!isMaintenancePlan && setOption(undefined)
		}
	}, [imNotSure, isMaintenancePlan])

	useEffect(() => {
		if (option) {
			setDSeconds('')
			setDMinutes('')
			setDHours('')
		}
	}, [option])

	useEffect(() => {
		if (isCasualPace) {
			const timeM = getCalculatedGoalTimeByPace(getRaceDistance('half') as number, casualMinutes, casualSeconds)

			setHours(Math.floor(timeM / 60))
			setMinutes(Math.floor(timeM % 60))
			setSeconds(Math.floor((timeM * 60) % 60))
		}
	}, [casualSeconds, casualMinutes, isCasualPace])

	return isLoading ? <Grid container xs={12} sx={{ height: '100%', flexDirection: 'column', px: 3 }} justifyContent='center' alignItems='center'>
		<Box sx={{ height: '80px', width: '80px' }}>
			<Lottie animationData={RunningLoader} />
		</Box>
		<Box sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>
			Loading plan recommendations...
		</Box>
	</Grid> : <Box sx={{ px: 3 }}>
		<SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
			{isMaintenancePlan ? "Let’s build out your pacing." : "What is your goal finish time on race day?"}
		</SH2>
		<P1 sx={{ width: '100%' }}>
			{isMaintenancePlan ? "Enter a recent race time or your conversation pace to personalize your pacing strategy." : `We’ll use this information to personalize the pacing to hit your race day goals.`}
		</P1>
		{isMaintenancePlan && <Box sx={{ display: 'flex', width: '170px', height: 'fit-content', flexDirection: 'column' }}>
			<Grid container sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', height: '40px' }} xs={12}>
				Pacing based on:
			</Grid>
			<RSButton1 input onClick={() => setImNotSure(true)} inputSX={{ width: '140px' }}>
				{option}
			</RSButton1>
		</Box>}
		<Grid container xs={12}>
			<Box sx={{ display: 'flex', pb: 2, pr: 2, width: 'fit-content', flexDirection: 'column' }}>

				<Grid container sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', height: '40px' }} xs={12}>
					Finish time:
				</Grid>
				<Grid container >
					{
						!removeHours && <Box sx={{ mr: 1 }}>
							<RSInputAdornment2 wholeNumber column value={hours} setValue={setHours} label={'Hours'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
						</Box>
					}
					<Box sx={{ mr: 1 }}>
						<RSInputAdornment2 wholeNumber column max={59} value={isCasualPace ? casualMinutes : minutes} setValue={isCasualPace ? setCasualMinutes : setMinutes} label={'Min'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
					</Box>
					<Box>
						<RSInputAdornment2 wholeNumber column max={59} value={isCasualPace ? casualSeconds : seconds} setValue={isCasualPace ? setCasualSeconds : setSeconds} label={'Sec'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
					</Box>
				</Grid>
			</Box>
		</Grid>

		{!isMaintenancePlan && <Grid container xs={12} sx={{ height: 'auto' }} alignItems='center'>
			<Box className='pointer blue-text' onClick={() => setImNotSure(true)} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em', textDecoration: 'underline' }}>
				{`I'm not sure`}
			</Box>
		</Grid>}

		<RSDrawer
			bottom
			fitContent
			popUpWidth='400px'
			open={imNotSure}
			onClose={() => setImNotSure(false)}
		>
			<Box sx={{ p: 3 }}>
				<H1 size={1}>{isMaintenancePlan ? "Change your event" : "Calculate your goal finish time"}</H1>
				<P1 sx={{ py: '10px' }} >
					{isMaintenancePlan ? `Select an event, then enter your time to build out your
maintenance plan pacing.`: `Select a running option below and we'll calculate a goal time for you.`}
				</P1>
				<SH2 sx={{ py: '10px' }}>
					Choose an option:
				</SH2>
				<Grid container xs={12} justifyContent='flex-start'>
					{activities.map((activity: any, ai: number) => {
						const isActive = activity === option
						if (activity.toLowerCase().includes(mergedStageDetails?.raceType.toLowerCase())) return
						return <Grid item container key={'activity-' + ai} sx={{ height: '40px', width: 'auto', mb: '10px', mr: '10px' }} justifyContent='center' alignItems='center'>
							<RSButton2 ghost={!isActive} sx={{ height: '100%', flexDirection: 'column' }}
								onClick={() => handleChangeOption(activity)}>
								<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px', px: '10px' }}>{activity}</Box>
							</RSButton2>
						</Grid>
					})}
				</Grid>
				{!isMaintenancePlan && <>
					<SH2 sx={{ py: '10px' }}>
						Enter time:
					</SH2>
					<Grid container xs={12} sx={{ pb: 4 }} alignItems='flex-start'>
						{(option != '5K' && option != 'Casual Pace' && !isMaintenancePlan || isMaintenancePlan && !isCasualPace && option != '5K') && <Box sx={{ mr: 1 }}>
							<RSInputAdornment2 wholeNumber column value={dHours} setValue={setDHours} label={'Hours'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
						</Box>}
						<Box sx={{ mr: 1 }}>
							<RSInputAdornment2 wholeNumber column max={59} value={dMinutes} setValue={setDMinutes} label={'Min'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
						</Box>
						<Box>
							<RSInputAdornment2 wholeNumber column max={59} value={dSeconds} setValue={setDSeconds} label={'Sec'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
						</Box>
					</Grid>
				</>}
				{!isMaintenancePlan && <RSButton1 disabled={!option || !dMinutes || !dSeconds} sx={{ maxWidth: '375px' }}
					onClick={() => {
						// getSuggestedGoalTimeAI()
						getCalculatedGoalTime()
						setImNotSure(false)
					}}
				>
					{isMaintenancePlan && !isCasualPace ? 'Set Goal Time' : `Calculate Goal Time`}
				</RSButton1>}
			</Box>
		</RSDrawer>
	</Box>
}

export default PlanPacing;
