/* eslint-disable @typescript-eslint/no-unused-vars */
import { date, strtotime } from "locutus/php/datetime"
import { explode } from "locutus/php/strings";
import trim from "locutus/php/strings/trim";
import { intval } from "locutus/php/var";
import useTrainingPlanUtils from "../../../hooks/useTrainingPlanUtils"
import { GetMarathonGoals, GetUserCustomPace } from "../../../modules/trainingPlanActions";
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from "../build/DaysOptions"
import { IBuildDayParams } from "../types"
import newVideos from '../../../modules/videos/new-videos.json'
import programVidoes from '../../../modules/videos/programVideos.json'
import useWeather from "./useWeather";
import useStore from "../../../store/useStore";
import { ITrainingPlanStore } from "../../../store/createTrainingPlanStore";
import moment from "moment";
import videoText from '../../../modules/videos/video-text.json'
import { UserNotesRecord } from "../../../api/v2/types";
import level1TrainingWorkouts from '../../../modules/videos/level1-training-workouts.json'
import allExercises from "../../../modules/videos/allNewExercises.json";

const MARATHON = 26.2188;
const TENK = 6.21371192237;
const HALF_MARATHON = 13.1094;
const MULTIPLIER = 1.60934;
const EIGHTK = 4.97096944;
const TENMILE = 10;

type pace = 'Base' | 'Easy' | 'Interval' | 'Threshold' | 'Marathon' | 'Repetition'

const useBuildTrainingPlan = (level?: number, raceType?: number, planType?: string) => {

    const { ConvertM2Mt, convertDistance, getThisDate, CalcBPace, CalcEPace, CalcIPace, CalcMPace, CalcMiscPace, CalcRPace, CalcTPace, isWeekend, ConvertH2M, ConvertM2H, ConvertT2MS, ConvertS2M, DaysBetween, convertDateToUTC, calcRP } = useTrainingPlanUtils()

    const { currentLocation } = useStore((state: ITrainingPlanStore) => state)

    const { getAdjustment, getWTA, weatherData } = useWeather()

    const buildDay = (params: IBuildDayParams) => {
        return params
    }

    const getOverridePace = async (userId: number, abort: AbortSignal) => {
        const customPace = await GetUserCustomPace(userId, abort);
        return customPace
    }

    const swapProgramToExercises = (workouts: any, typeOfWorkout: string | undefined, marId: number) => {
        let newWorkOuts = workouts;
        const newExercises: any = []
        const currentWoroutReplacement = localStorage.getItem('currentWoroutReplacement')
        const currentMarId = Number(localStorage.getItem('currentMarId')) | marId

        if (typeOfWorkout === "Base6" || typeOfWorkout === "Base3" || typeOfWorkout === "Yoga") {
            // console.log(typeOfWorkout)
            const programItem = workouts?.filter((x: any) => x.title.toLocaleLowerCase().includes('basesix') || x.title.toLocaleLowerCase().includes('base3') || x.title.toLocaleLowerCase().includes('yoga'))

            // console.log(programItem)

            const repType = currentWoroutReplacement === "program" && marId !== currentMarId ? "workout" : "program"

            const customExercises = level1TrainingWorkouts.filter((x: any) => x?.replaceWorkout === repType)
            localStorage.setItem('currentWoroutReplacement', repType)
            localStorage.setItem('currentMarId', marId.toString())


            customExercises.map((vid, index) => {
                const videoId = vid?.url.split('/')[5]

                const newExercise = allExercises.find(x => x.video_id == Number(videoId))

                const exercises = {
                    mid: newWorkOuts[0].mid || marId,
                    workoutId: index + 1,
                    link: `https://app.runsmartonline.com/#/exercises/${videoId}`,
                    title: 'RunSmart Exercise',
                    description: newExercise?.tips_description
                }
                // console.log(exercises)

                newExercises.push(exercises)

            })

            programItem.map((x: any) => {
                newWorkOuts = newWorkOuts.filter((a: any) => a.workoutId !== x.workoutId)
            })

            newWorkOuts = [...newWorkOuts, ...newExercises]
            newWorkOuts = newWorkOuts.filter((a: any, i: any) => newWorkOuts.findIndex((s: any) => a.workoutId === s.workoutId) === i)
            newWorkOuts = newWorkOuts.filter((a: any, i: any) => newWorkOuts.findIndex((s: any) => a.link === s.link) === i)
        }
        // else {
        //     if (workouts.length > 0 && workouts.filter((x: any) => x.title?.length > 0)) {
        //         const repType = currentWoroutReplacement === "program" ? "workout" : "program"
        //         const customExercises = level1TrainingWorkouts.filter((x: any) => x?.replaceWorkout === repType)
        //         localStorage.setItem('currentWoroutReplacement', repType)

        //         // console.log(customExercises)

        //         customExercises.map((vid, index) => {
        //             const videoId = vid?.url.split('/')[5]

        //             const newExercise = allExercises.find(x => x.video_id == Number(videoId))
        //             console.log(newExercise)

        //             const exercises = {
        //                 mid: newWorkOuts[0]?.mid || marId,
        //                 workoutId: newWorkOuts[0]?.workoutId + (index + 9999) || index,
        //                 link: `https://app.runsmartonline.com/#/exercises/${videoId}`,
        //                 title: 'RunSmart Exercise',
        //                 description: newExercise?.tips_description
        //             }
        //             // if (workouts?.find((x: any) => x.workoutId == exercises.workoutId))
        //             newExercises.push(exercises)
        //         })

        //         // console.log(newExercises)

        //         newWorkOuts = [...workouts, ...newExercises]
        //         // console.log(newWorkOuts)
        //         newWorkOuts = newWorkOuts.filter((a: any, i: any) => newWorkOuts.findIndex((s: any) => a.workoutId === s.workoutId) === i)
        //         newWorkOuts = newWorkOuts.filter((a: any, i: any) => newWorkOuts.findIndex((s: any) => a.link === s.link) === i)
        //     }
        // }

        return newWorkOuts
    }

    const swapProgramWorkouts = (workouts: any, typeOfWorkout: string) => {
        let newWorkOuts = workouts;

        if (typeOfWorkout === "Base6") {
            const baseSixItem = workouts?.filter((x: any) => x.title.toLocaleLowerCase().includes('basesix'))
            const filteredIndex = workouts?.map((obj: any, index: any) => obj.workoutId === baseSixItem[0]?.workoutId ? index : -1)
                .filter((index: number) => index !== -1)[0];

            const day = baseSixItem[0]?.prevDay && Number(baseSixItem[0]?.prevDay) >= 7 ? Number(baseSixItem[0]?.prevDay) : baseSixItem[0]?.title?.split(' ')[2]

            const newBaseProgram = programVidoes.find((x) => x.type.toLocaleLowerCase() === "workout_" + (day <= 6 ? day : day - 6) && x.item === (day <= 6 ? 'Base3' : 'Base6'))

            // console.log(newBaseProgram)

            newWorkOuts = [...workouts.filter((x: any) => x.workoutId !== baseSixItem[0].workoutId), {
                ...workouts[filteredIndex],
                link: `https://app.runsmartonline.com/#/programs/${day <= 6 ? 'Base3' : 'Base6'}/${newBaseProgram?.id}`,
                title: day <= 6 ? `Base3 Day ${day <= 6 ? day : day - 6}` : `BaseSix Day ${day <= 6 ? day : day - 6}`,
                description: (videoText as any)[newBaseProgram?.id as number]?.descriptions[0] || '',
                prevDay: day
            }]

            // console.log(newWorkOuts)

        }

        else if (typeOfWorkout === "Yoga") {
            const yogaItem = workouts?.filter((x: any) => x.title.toLocaleLowerCase().includes('yoga'))
            const filteredIndex = workouts?.map((obj: any, index: any) => obj.workoutId === yogaItem[0]?.workoutId ? index : -1)
                .filter((index: number) => index !== -1)[0];

            const day = yogaItem[0]?.prevDay && Number(yogaItem[0]?.prevDay) >= 7 ? Number(yogaItem[0]?.prevDay) : yogaItem[0]?.title?.split(' ')[3]

            const newYoga = programVidoes.find((x) => x.type.toLocaleLowerCase() === (day <= 6 ? "intro_" : "day_") + (day <= 6 ? day : day - 6) && x.item === "Yoga")

            newWorkOuts = [...workouts.filter((x: any) => x.workoutId !== yogaItem[0].workoutId), {
                ...workouts[filteredIndex],
                link: 'https://app.runsmartonline.com/#/programs/yoga/' + newYoga?.id,
                title: day <= 6 ? newYoga?.item + ' ' + newYoga?.main_headline : `RunSmart Yoga Day ${(day <= 6 ? day : day - 6)}`,
                description: (videoText as any)[newYoga?.id as number]?.descriptions[0] || '',
                prevDay: day
            }]
        }

        return newWorkOuts
    }

    const getTypeOfWorkouts = (workouts: any) => {
        const filtered = (params: string) => workouts?.filter((x: any) => x?.title?.toLowerCase()?.includes(params))
        if (filtered('basesix')?.length > 0)
            return 'Base6'
        else if (filtered('yoga')?.length > 0)
            return 'Yoga'
        else if (filtered('base3')?.length > 0)
            return 'Base3'
        else ''
    }

    const handleAdjustPaceByLevel = (marathonData: any, userInfo: any, marathonKey: number, raceDay: number, currentLevel?: number) => {

        let dayData = marathonData;
        // console.log(dayData)

        const activity = analyseDay(marathonData?.marathonPaces, marathonKey, raceDay)
        // console.log(activity)

        const typeOfWorkout = getTypeOfWorkouts(dayData?.workouts)

        const training_level =  currentLevel || level

        if (training_level && planType !== "GPT") {
            if ((Number(training_level) == 1 && (activity === "Track" || activity === "Tempo")) || (Number(training_level) == 2 && activity === "Track"))
                dayData = {
                    ...dayData,
                    ...dayData['marathonPaces'] = [{
                        ...dayData?.marathonPaces[0],
                        pace: 'Base',
                        notes: `${dayData?.marathon?.distance?.toFixed()} ${dayData?.marathon?.distance > 1 ? 'miles' : 'mile'
                            }`
                    }]
                }

            const crossTrain = dayData?.workouts?.filter((x: any) => x?.title?.toLocaleLowerCase().includes('cross') || x?.title?.toLocaleLowerCase().includes('bike'));

            if (training_level != 0 && training_level <= 2 && (userInfo?.type == 1 || userInfo?.type == 0 || userInfo?.type == 8)) {
                dayData = {
                    ...dayData,
                    ...dayData['workouts'] =
                    (typeOfWorkout === "Base6" || typeOfWorkout === "Base3" || typeOfWorkout === "Yoga") && training_level == 2 ? swapProgramWorkouts(dayData?.workouts?.filter((x: any) => !(x?.title?.toLowerCase()?.includes('60 min') || x?.description?.toLowerCase()?.includes('60 min'))), typeOfWorkout)
                        : (typeOfWorkout === "Base6" || typeOfWorkout === "Base3" || typeOfWorkout === "Yoga") && training_level == 1 || training_level == 1 && crossTrain.length > 0 ? swapProgramToExercises(dayData?.workouts?.filter((x: any) => !(x?.title?.toLowerCase()?.includes('60 min') || x?.description?.toLowerCase()?.includes('60 min'))), typeOfWorkout, dayData?.marathon?.id)
                            : dayData?.workouts?.filter((x: any) => !(x?.title?.toLowerCase()?.includes('60 min') || x?.description?.toLowerCase()?.includes('60 min')))
                }

                // console.log(dayData)
            }

            else if (training_level != 0 && training_level <= 2 && (userInfo?.type != 1 || userInfo?.type != 0)) {
                dayData = {
                    ...dayData,
                    ...dayData['workouts'] =
                    (typeOfWorkout === "Base6" || typeOfWorkout === "Base3" || typeOfWorkout === "Yoga") && training_level == 2 ? swapProgramWorkouts(dayData?.workouts, typeOfWorkout)
                        : (typeOfWorkout === "Base6" || typeOfWorkout === "Base3" || typeOfWorkout === "Yoga") && training_level == 1 || training_level == 1 && crossTrain.length > 0 ? swapProgramToExercises(dayData?.workouts, typeOfWorkout, dayData?.marathon?.id)
                            : dayData?.workouts
                }
            }

            return dayData
        }
    }

    const getTimePercentage = (timeStr: string, percentage: number) => {

        const parseTime = (timeStr: string) => {
            const timeParts = timeStr.split(' ');
            let minutes = 0;
            let seconds = 0;

            timeParts.forEach(part => {
                if (part.includes('m')) {
                    minutes = parseInt(part.replace('m', ''));
                } else if (part.includes('s')) {
                    seconds = parseInt(part.replace('s', ''));
                }
            });

            return { minutes, seconds };
        }

        const toTotalSeconds = (minutes: number, seconds: number) => {
            return (minutes * 60) + seconds;
        }

        const toTimeFormat = (totalSeconds: number) => {
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes}m ${seconds}s`;
        }

        const { minutes, seconds } = parseTime(timeStr);
        const totalSeconds = toTotalSeconds(minutes, seconds);
        const percentageSeconds = Math.floor(totalSeconds * ((percentage * 100) / 100));

        return toTimeFormat(percentageSeconds);

    }

    const calcRacePace = (isKm: boolean, targetm: number, distanceM?: number, userInfo?: any) => {
        const newRaceType = userInfo?.type || raceType
        if (newRaceType == 0 && targetm) {
            return calcRP(HALF_MARATHON, targetm * 2, isKm);
        }
        else if (newRaceType == 1 && distanceM) {
            return CalcMPace(distanceM, targetm as number, isKm);
        }
        else if (newRaceType == 2 && targetm) {
            const timeM = targetm * Math.pow((MARATHON / (TENK / 2)), 1.06)
            return CalcMiscPace(TENK / 2, timeM as number, isKm);
        }
        else if (newRaceType == 3 && targetm) {
            const timeM = targetm * Math.pow((MARATHON / TENK), 1.06)
            return CalcMiscPace(TENK, timeM as number, isKm);
        }
        else if (newRaceType == 5 && targetm) {
            const timeM = targetm * Math.pow((MARATHON / EIGHTK), 1.06)
            return CalcMiscPace(EIGHTK, timeM as number, isKm);
        }
        else if (raceType == 8 && targetm) {
            const timeM = targetm * Math.pow((MARATHON / TENMILE), 1.06)
            return CalcMiscPace(TENMILE, timeM as number, isKm);
        }
    }
    const extractPace = (paceValues: any, distance: number, wstack: number, vdot: number, isKm: boolean, targetm?: number, aggressAdjustVal?: number, customPaces?: Array<any>, currentDistance?: number, userInfo?: any) => {

        // console.log('===============================')
        // console.log('paceValues', paceValues)
        // console.log('distance', distance)
        // console.log('wstack', wstack)
        // console.log('vdot', vdot)
        // console.log('isKm', isKm)
        // console.log('targetm', targetm)
        // console.log('aggressAdjustVal', aggressAdjustVal)
        // console.log('customPaces', customPaces)
        // console.log('currentDistance', currentDistance)
        // console.log('===============================')

        const newPlanType = userInfo?.plan_type || planType
        const newRaceType = userInfo?.type || raceType
				const newLevel = Number(userInfo?.training_level) || level

        const pace = paceValues?.pace
        const notes = paceValues?.notes
        const orderId = paceValues?.orderid
        const multiplier = paceValues?.multiplier
        let customPace = undefined;
        let distVal = Math.round(distance)
        let distanceM = Math.round(distance)
        let setPace;
        let rawPace;
        let weatherPaces;
        const isNewHM = newPlanType === "GPT" && newRaceType == 0
        //console.log(newPlanType, newRaceType)
        // console.log(pace, newPlanType == "GPT" && targetm ? targetm * multKRuns : targetm, orderId)
        if (notes && trim(notes) !== "") {
            distVal = notes.replace(/[^\d]/g, '');
            distanceM = distVal;
        }
        distVal = ConvertM2Mt(Math.round(distVal))

        if (customPaces && customPaces.length > 0) {
            customPace = customPaces.find(x => x.pid == (paceValues?.paceId + (paceValues?.mar_id * 10000)))
            // customPace && console.log('!!! found user custom pace', customPace)
            // !customPace && console.log('!!! no user custom pace found', customPace)
        }


        if (customPace)
            setPace = customPace.pace
        else {
            switch (pace) {
                // case 'Base':
                //     setPace = CalcBPace(distVal, vdot, isKm);
                //     break;

                case 'Easy':
                    setPace = CalcEPace(distVal, vdot, isKm);
                    break;

                case 'Marathon':
                    setPace = CalcMPace(distanceM, targetm as number, isKm);
                    break;

                case 'Threshold':
                    setPace = CalcTPace(distVal, vdot, isKm);
                    // setPace = newLevel == 1 ? CalcBPace(distVal, vdot, isKm) : CalcTPace(distVal, vdot, isKm);
                    break;

                case 'Tempo':
                    setPace = CalcTPace(distVal, vdot, isKm);
                    // setPace = newLevel == 1 ? CalcBPace(distVal, vdot, isKm) : CalcTPace(distVal, vdot, isKm);
                    break;

                case 'HalfMarathon':
                    setPace = newPlanType === "GPT" && targetm ? calcRP(HALF_MARATHON, targetm * 2 as number, isKm) : CalcMiscPace(HALF_MARATHON, targetm as number, isKm);
                    break;

                case 'Repetition':
                    setPace = CalcRPace(distVal, vdot, isKm);
                    // setPace = newLevel == 1 || newLevel == 2 ? CalcBPace(distVal, vdot, isKm) : CalcRPace(distVal, vdot, isKm);
                    break;

                // case 'Interval':
                //     setPace = CalcIPace(distVal, vdot, isKm);
                //     // setPace = newLevel == 1 || newLevel == 2 ? CalcBPace(distVal, vdot, isKm) : CalcIPace(distVal, vdot, isKm);
                //     break;

                case '10k':
                    if (newPlanType === "GPT")
                        setPace = calcRacePace(isKm, targetm as number, userInfo)
                    else
                        setPace = CalcMiscPace(TENK, targetm as number, isKm);
                    break;
                case '10K':
                    setPace = CalcMiscPace(TENK, targetm as number, isKm);
                    break;
                case '8k':
                    setPace = calcRacePace(isKm, targetm as number, userInfo)
                    break;
                case '5k':
                    setPace = calcRacePace(isKm, targetm as number, userInfo)
                    break;

                case 'Race Day':
                    if (newPlanType === "GPT" && targetm) {
                        setPace = calcRacePace(isKm, targetm as number, distance, userInfo)
                    }
                    else {
                        if (orderId == 0)
                            setPace = CalcMiscPace(HALF_MARATHON, targetm as number, isKm);
                        else if (orderId == 1)
                            setPace = CalcMPace(currentDistance as number, targetm as number, isKm);
                        else if (orderId == 2)
                            setPace = CalcMiscPace(TENK / 2, targetm as number, isKm);
                        else if (orderId == 3)
                            setPace = CalcMiscPace(TENK, targetm as number, isKm);
                    }
                    break;
            }

            if (newRaceType == 0 && pace == "Race Pace" && targetm && newPlanType !== "GPT") {
                const timeM = targetm * Math.pow((MARATHON / HALF_MARATHON), 1.06)
                setPace = CalcMiscPace(HALF_MARATHON, timeM as number, isKm);
            }
            else if (newPlanType === "GPT" && pace == "Race Pace" && targetm)
                setPace = calcRacePace(isKm, targetm as number, userInfo)

            else if (pace?.toLowerCase()?.includes('base') || pace?.toLowerCase()?.includes('long')) {
                setPace = isNewHM ? CalcBPace(distVal, vdot, isKm) : CalcBPace(distVal, vdot, isKm);;
            }

            else if (pace?.toLowerCase()?.includes('interval') || pace?.toLowerCase()?.includes('track')) {
                setPace = CalcIPace(distVal, vdot, isKm);
            }

            if (aggressAdjustVal && aggressAdjustVal > 0) {
                if (pace == 'Repetition' || pace == 'Threshold' || pace == 'Interval') {
                    const aggressAdjustValue = aggressAdjustment(setPace as string, aggressAdjustVal);
                    if (aggressAdjustValue) {
                        setPace = aggressAdjustValue;
                    }
                }
            }
        }

        if (multiplier) {
            setPace = getTimePercentage(setPace, multiplier)
        }

        if (setPace != undefined) {
            // weatherPaces = [setPace, weatherAdjust(setPace)]
            rawPace = setPace;
            setPace = isKm ? setPace + '/ km' : setPace + '/ mile'
        }

        else
            setPace = ''
        return { setPace, rawPace, weatherPaces }
    }

    const weatherAdjust = (pace: any) => {
        const adjustment = getAdjustment() as number;
        if (adjustment > 1 && pace) {
            pace = explode(" ", pace);
            const min = intval(pace[0]);
            const sec = intval(pace[1]);
            pace = ConvertH2M(0, min, sec);
            let converted_pace: any = +pace * adjustment;
            converted_pace = ConvertT2MS(converted_pace);
            return converted_pace;
        }
        return false;
    }

    const getWTAValue = () => {
        return getWTA()
    };

    const getAdjustmentValue = getAdjustment;

    const getWeatherData = weatherData;


    const aggressAdjustment = (pace: string, aaggressAdjustment: number) => {
        const adjustment = aaggressAdjustment;
        if (adjustment > 0) {
            pace = explode(" ", pace);
            const min = intval(pace[0]);
            const sec = intval(pace[1]);
            const paceValue = ConvertH2M(0, min, sec);
            const converted_pace_value = paceValue * adjustment;
            const converted_pace = ConvertM2H(converted_pace_value);
            return converted_pace;
        }
        return false;
    }

    const computelWeekTotalDistance = (marathonValues: Array<any>, userNotes?: Array<UserNotesRecord>) => {
        let totalDistance = 0;
        marathonValues.forEach((val) => {
            if (val.length != 0 && val.marathon.distance != undefined && val.marathon.distance != 1000) {
                const userNote = userNotes?.find(x => x.marid == val.marathon.id)
                totalDistance += userNote && userNote.distance ? userNote.distance : val?.marathon?.distance
            }

        })

        return totalDistance.toFixed(1).toString().replace(/(\.0+|0+)$/, '');
    }

    const getDailyActivityDisplay = (params: any, userInfo: any, paceRaw: Array<any>, marathonKey: number, raceDay: number | null, userNotes?: Array<UserNotesRecord>) => {
        if (params) {
            const { marathon, workouts, marathonPaces } = params
            const userNote = userNotes?.find(x => x.marid == marathon?.id)
            let analyzed = analyseDay(paceRaw, marathonKey, raceDay);

            const distanceConvertionKM = (!marathon?.converted ? convertDistance(+ConvertM2Mt(userNote && userNote.distance ? userNote.distance : marathon?.distance) / 1000) : (userNote && userNote.distance ? userNote.distance : marathon?.distance)) + ' km'

            const isNewAndKRuns = (userInfo?.planType === "GPT" || planType === "GPT") && (raceType == 2 || userInfo?.type == 2 || raceType == 3 || userInfo?.type == 3 || raceType == 5 || userInfo?.type == 5)

            let crossTrainTypes = []
						if (workouts) {
							for (const workout of workouts) {
									const typeStrength = workout?.title?.toLowerCase()?.includes('base') || workout?.description?.toLowerCase()?.includes('base') || workout?.workout_type?.toLowerCase()?.includes('strength')
									const typeYoga = workout?.title?.toLowerCase()?.includes('yoga') || workout?.description?.toLowerCase()?.includes('yoga') || workout?.workout_type?.toLowerCase()?.includes('yoga')
									const typeStretch = workout?.title?.toLowerCase()?.includes('stretch') || workout?.description?.toLowerCase()?.includes('stretch') || workout?.workout_type?.toLowerCase()?.includes('stretch')
									const typeRoll = workout?.title?.toLowerCase()?.includes('roll') || workout?.description?.toLowerCase()?.includes('roll') || workout?.workout_type?.toLowerCase()?.includes('roll')
									const typeExercise = workout?.workout_type?.toLowerCase()?.includes('exercise')
									if (typeStrength) crossTrainTypes.push('strength')
									else if (typeYoga) crossTrainTypes.push('yoga')
									else if (typeRoll) crossTrainTypes.push('roll')
									else if (typeStretch) crossTrainTypes.push('stretch')
									else if (typeExercise) {
											crossTrainTypes.push('roll')
											crossTrainTypes.push('stretch')
									}
							}
						}

            crossTrainTypes = Array.from(new Set(crossTrainTypes))

            const crossTrainActivities = crossTrainTypes.length > 0 ? "Cross Train | | " + crossTrainTypes.join(',') : "Cross Train | | Stretch"
            analyzed = (userInfo?.training_level == 1 || level == 1) && (analyzed == "Track" || analyzed == "Tempo") ? "Base" : (userInfo?.training_level == 2 || level == 2) && (analyzed == "Track") ? "Base" : analyzed

            if (marathonPaces && marathonPaces[0]?.pace === "Race Day") {
                const distance = userInfo?.km === 'Y' ? (convertDistance(+ConvertM2Mt(userNote && userNote.distance ? userNote.distance : marathon?.distance) / 1000)) + " km" : convertDistance(userNote && userNote.distance ? userNote.distance : marathon?.distance) + " miles"
                return distance + " | " + "Race Day";
            }
            else if (!userNote?.distance && workouts && workouts?.length != 0 && workouts[0]?.title !== "" && marathon?.distance == 0) {
                if ((userInfo?.training_level != 0 && userInfo?.training_level <= 2) && (userInfo?.type == 1 || userInfo?.type == 0 || userInfo?.type == 8) && marathon?.training_goal <= 40 && workouts.filter((x: any) => x?.title?.toLowerCase()?.includes('60 min') || x?.description?.toLowerCase()?.includes('60 min')).length > 0 && workouts.filter((x: any) => x.link !== '').length == 0)
                    return "Rest"
                else return crossTrainActivities
            }
            else if (workouts && workouts?.length != 0 && workouts[0]?.title === "" && marathon?.distance == 0 || (workouts?.length == 0) || (userNote && userNote.distance === 0))
                return "Rest"
            else if (marathon?.distance == 1000 || marathon?.distance == 1609 || (paceRaw?.length && paceRaw[0]?.type === "race_week"))
                return "Race Pace" + (isNewAndKRuns && crossTrainTypes?.length > 0 ? (" | " + crossTrainTypes) : '')
            else if (analyzed) {
                const distance = userInfo?.km === 'Y' ? distanceConvertionKM : convertDistance(userNote && userNote.distance ? userNote.distance : marathon?.distance) + " miles"
                return distance + " | " + analyzed + (crossTrainTypes?.length > 0 ? (" | " + crossTrainTypes) : '')
            }
            else if (marathon?.distance > 0 || userNote && userNote.distance && userNote.distance > 0) {
                const distance = userInfo?.km === 'Y' ? distanceConvertionKM : convertDistance(userNote && userNote.distance ? userNote.distance : marathon?.distance) + " mi"

                if (workouts && workouts?.length != 0 && workouts[0]?.title !== "") {
                    return distance + " | " + crossTrainActivities
                }

                return distance + " Miles | " + marathonPaces[0]?.pace + (crossTrainTypes?.length > 0 ? (" | " + crossTrainTypes) : '')
            }

            else if (marathon?.distance == 0 && workouts?.length == 0 || workouts?.length == 0 && marathonPaces?.length == 0 || marathon?.distance == 0 && userNote?.distance == 0)
                return "Rest"
        }
        else
            return "Rest"
    }

    const convertPace = (text: string, pace: string, isKm: boolean) => {
        const paceRegex = new RegExp(/([0-9]{1,2})m\s+([0-9]{1,2})s/);
        const textRegex = new RegExp(/([0-9]{3,4})m/)
        const paceMatch = paceRegex.exec(pace)
        const textMatch = textRegex.exec(text)
        let paceConverted;
        let newVal;

        const time: number = paceMatch ? +((+paceMatch[1] * 60) + +paceMatch[2]) : 0;
        let units = 0.0006213712;
        if (isKm) {
            units = 0.001;
        }

        if (textMatch) {
            newVal = +textMatch[1] * +time * +units;
            const pace_conv = ConvertS2M(newVal as number);

            return " | Time/Interval: " + pace_conv;
        }
    }

    const convertPaceBase = (text: string, pace: string, isKm: boolean, divisor?: number) => {
        const paceRegex = new RegExp(/([0-9]{1,2})m\s+([0-9]{1,2})s/);
        const textRegex = new RegExp(/([0-9]{3,4})m/)
        const paceMatch = paceRegex.exec(pace)
        const textMatch = textRegex.exec(text)
        let paceConverted;
        let newVal;
        const div = divisor ? divisor : 1

        const time: number = paceMatch ? +((+paceMatch[1] * 60) + +paceMatch[2]) : 0;
        let units = 0.0006213712;
        if (isKm) {
            units = 0.001;
        }

        if (textMatch) {
            newVal = +textMatch[1] * (+time / div) * +units;
            const pace_conv = ConvertS2M(newVal as number);

            return pace_conv;
        }
        else
            return ""
    }

    const distanceInterpret = (text: string, isKm: boolean, pace?: string, distance?: number, customNote?: string, customDistance?: number) => {
        // console.log(distance, pace, text)
        // text.indexOf("mile")
        if ((pace === "Base" || pace === "Threshold" || pace === "Marathon" || pace === "Easy" || pace === "HalfMarathon" || pace === "Interval" || pace === "Repetition" || pace === "10K") && (text === '' || text.length == 1)) {
            const textVal = distance ? `${distance.toFixed()} ${distance > 1 ? 'miles' : 'mile'}` : ''
            return distanceMarker(textVal, isKm);
        }
        else if (text.indexOf("mile") < 0 || text?.toLowerCase()?.indexOf("time trial") > 0 || text?.toLowerCase()?.indexOf("cool down") > 0) {
            if (text.includes('cool down'))
                return distanceMarker(text, isKm)
            else
                return customNote ? customNote : text.charAt(0).toUpperCase() + text.slice(1);
        }
        else {
            if (customDistance) {
                const textVal = customDistance ? `${customDistance.toFixed()} ${customDistance > 1 ? 'miles' : 'mile'}` : ''
                return distanceMarker(textVal, isKm);
            }
            else
                return distanceMarker(text, isKm);
        }

    }

    const distanceMarker = (pace: any = null, isKm: boolean) => {
        if (!pace) {
            if (isKm) {
                return "km";
            }
            return "mile";
        } else {
            if (isKm) {
                const number = +pace.replace(/[^\d.]/g, '')
                const kmConvert = +(number * MULTIPLIER).toFixed(1)
                if (pace.indexOf("mile") > 1)
                    if (kmConvert > 1)
                        return pace.includes('cool down') ? kmConvert + ' cool down' : kmConvert + " kms"
                    else
                        return pace.includes('cool down') ? kmConvert + ' cool down' : kmConvert + " km"
            }
            const paceNumber = +pace.replace(/[^\d.]/g, '')

            if (pace.indexOf("mile") > 1)
                if (paceNumber > 1)
                    return paceNumber + " miles"
                else
                    return paceNumber + " mile"
        }
    }

    const distanceMarkerGoals = (pace: any = null, isKm: boolean) => {
        if (!pace) {
            if (isKm) {
                return "km";
            }
            return "mile";
        } else {
            if (isKm) {
                const number = +pace
                const kmConvert = +(number * MULTIPLIER).toFixed(1)
                if (kmConvert > 1)
                    return kmConvert.toFixed(1) + " kms"
                else
                    return kmConvert.toFixed(1) + " km"
            }
            const paceNumber = +pace

            if (paceNumber > 1)
                return paceNumber.toFixed(1) + " miles"
            else
                return paceNumber.toFixed(1) + " mile"
        }
    }


    const getRaceDayDetails = (userInfo: any) => {
        const { monday_start, race_date } = userInfo
        let sundayRace = false;
        let saturdayRace = false;

        let raceDay: number = date('w', strtotime(moment(race_date).utc().format("MM/DD/YYYY")));

        if (monday_start && raceDay == 0) {
            sundayRace = true;
            raceDay = 6;
        } else if (monday_start && raceDay == 6) {
            saturdayRace = true;
            raceDay = 5;
        } else if (monday_start && raceDay == 1) {
            raceDay = 0;
        }

        return { sundayRace, saturdayRace, raceDay }
    }

    const getRaceDetailsValues = (curentDetails: any, userInfo: any, raceDay: any, date?: string) => {

        // let today = date("l");

        const currentDateTime = strtotime(moment(date || '').utc().format("MM/DD/YYYY"));
        const raceDateTime = strtotime(moment(userInfo.race_date).utc().format("MM/DD/YYYY"));
        const startDateTime = strtotime(moment(userInfo.start).utc().format("MM/DD/YYYY"));
        let daysUntilRace = Math.round((((raceDateTime - currentDateTime) / 60) / 60) / 24);
        daysUntilRace = daysUntilRace < 0 ? 0 : daysUntilRace
        const planLength = Math.round((raceDateTime - startDateTime) / 60 / 60 / 24);
        let daysCompleted = Math.round((((currentDateTime - startDateTime) / 60) / 60) / 24);
        // today = selectedDay ? DAYS_OF_WEEK[selectedDay] : today;

        if (daysCompleted < 0) daysCompleted = 0;

        const percentCompleted = daysUntilRace > 0 ? +Math.round((daysCompleted / planLength) * 100).toPrecision(2) : 100

        return {
            ...curentDetails,
            currentDateTime,
            raceDateTime,
            startDateTime,
            planLength,
            daysUntilRace,
            daysCompleted,
            raceDay,
            percentCompleted: percentCompleted
        }
    }

    const getWeekDetails = (userInfo: any, selectedWeek: any) => {
        let thisDate;
        const { start, monday_start } = userInfo
        const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK;
        days.map((val, idx) => {
            thisDate = getThisDate(start, selectedWeek.key, idx as number, monday_start);
        })
        return thisDate
    }

    const analyseDay = (paceRaw: Array<any>, marathon: number, raceDay: number | null) => {
        const counter = {
            Base: 0,
            Easy: 0,
            Interval: 0,
            Threshold: 0,
            Marathon: 0,
            Repetition: 0,
            Progression: 0,
            Tempo: 0,
            '10K': 0
        }

        paceRaw?.forEach((val) => {
            if (val.pace === "HalfMarathon") {
                counter.Marathon += 1
            }
            else if (val.pace === "10K" || val.pace === "10k") {
                counter.Marathon += 1
                counter["10K"] += 1
            }
            else
                counter[val.pace as pace] += 1
        })


        if (counter.Base > 0 && counter.Easy == 0 && counter.Interval == 0 && counter.Threshold == 0 && counter.Marathon == 0 && counter.Repetition == 0) {
            return "Base";
        }

        if ((paceRaw?.length && paceRaw?.[0].type === "progression") || counter?.Progression > 0) {
            return "Progression";
        }

        if (counter.Interval == 0 && (counter.Threshold > 0 || counter.Tempo > 0) && counter.Marathon == 0 && counter.Repetition == 0) {
            return "Tempo";
        }

        if (counter.Base == 0 && counter.Easy > 0 && counter.Interval == 0 && counter.Threshold == 0 && counter.Marathon == 0 && counter.Repetition == 0) {
            return "Easy";
        }

        if ((counter.Interval > 0 || counter.Repetition > 0) && counter.Marathon == 0) {
            return "Track";
        }

        if (counter.Interval == 0 && counter.Threshold > 0 && counter.Marathon > 0 && counter.Repetition == 0) {
            return "Mixed";
        }

        if (raceDay == 1 && counter["10K"] == 0) {
            return "Race";
        }

        if (counter.Interval == 0 && counter.Repetition == 0 && counter.Marathon > 0 && counter.Threshold == 0 && marathon != 0) {
            return "Race";
        }

        if (counter.Interval == 0 && counter.Repetition == 0 && counter.Marathon > 0 && counter.Threshold == 0 && counter["10K"] > 0) {
            return "10K Pace";
        }

        if (counter.Interval == 0 && counter.Repetition == 0 && counter.Marathon > 0 && counter.Threshold == 0 && marathon == 0) {
            return "Mixed";
        }

        if (paceRaw?.length && paceRaw?.[0].type === "tempo") {
            return "Tempo";
        }

        if (paceRaw?.length && paceRaw?.[0]?.pace?.toLowerCase()?.includes('race')) {
            return "Race";
        }

        if (paceRaw?.length && (paceRaw?.[0]?.pace?.toLowerCase()?.includes('track') || paceRaw?.[0]?.pace?.toLowerCase()?.includes('interval'))) {
            return "Track";
        }

        if (paceRaw?.length && (paceRaw?.[0]?.pace?.toLowerCase()?.includes('base') || paceRaw?.[0]?.pace?.toLowerCase()?.includes('long'))) {
            return "Base";
        }

        if (!paceRaw) {
            return "Base"
        }
    }

    const timeUntilRace = (weeks: number, started: Date, startmonday: boolean, raceDate: any) => {
        const { sundayRace, saturdayRace, raceDay } = getRaceDayDetails({ monday_start: startmonday, race_date: raceDate })
        // let realStart;
        const noAdd = !sundayRace && !saturdayRace && raceDay == 0
        const less1Week = !sundayRace && !saturdayRace && raceDay == 1
        weeks--;
        const realDate = convertDateToUTC(started)
        const days = (weeks * 7) + 6 + (less1Week ? 0 : (noAdd && !startmonday ? 0 : noAdd && startmonday ? 1 : 7 - raceDay));
        // if (isWeekend(started)) {
        //     if (startmonday) {
        //         realStart = strtotime('next Monday', strtotime(realDate));
        //     } else {
        //         realStart = strtotime('next Sunday', strtotime(realDate));
        //     }
        // } else {
        const realStart = strtotime(realDate);
        // }
        return date("m/d/Y", strtotime("+" + days + " days", realStart));
    }
    const getWorkoutVideoDetailsV2 = (link: string) => {
        const videoId = link?.toLowerCase()?.includes('exercises') ? link.split("/")[5] : link.split("/")[6]
        const videos = newVideos as unknown as Array<any>
        let videoDetails: any;

        if (link?.toLowerCase()?.includes('exercises')) {
            const v = allExercises.find(x => x.video_id == Number(videoId))
            videoDetails = {
                img_title: `https://vumbnail.com/${videoId}.jpg`,
                item: 'Exercise',
                main_headline: v?.exercise_title,
                type: "exercise",
                exercise_type: v?.exercise_type
            }
        }
        else
            videoDetails = videos[+videoId]

        return videoDetails
    }

    const getWorkoutVideoDetails = (link: string) => {
        const videoId = level == 1 && !link.includes('foam') ? link.split("/")[5] : link.split("/")[6]
        const videos = newVideos as unknown as Array<any>
        let videoDetails: any;

        if (planType === "GPT") {
            return getWorkoutVideoDetailsV2(link)
        }

        else
            if (level == 1 && !link.includes('foam')) {
                const v = allExercises.find(x => x.video_id == Number(videoId))
                videoDetails = {
                    img_title: `https://vumbnail.com/${videoId}.jpg`,
                    item: 'Exercise',
                    main_headline: v?.exercise_title,
                    type: "exercise",
                    exercise_type: v?.exercise_type
                }
            }
            else if (link.includes('foam')) {
                const v = allExercises.find(x => x.video_id == Number(videoId))
                videoDetails = {
                    img_title: `https://vumbnail.com/${videoId}.jpg`,
                    item: 'Exercise',
                    main_headline: v?.exercise_title,
                    type: "exercise",
                    exercise_type: v?.exercise_type
                }
            }
            else {
                videoDetails = videos[+videoId]
            }

        return videoDetails
    }

    const getRaceGoalsWeek = (marathon: number, week: number, distance: number) => {
        let c_week;
        let b_week;
        if (marathon == 1) {
            marathon = 1;
            if (distance == 40 && week == 18) {
                c_week = 7;
                b_week = 15;
            }
            if (distance == 50 && week == 18) {
                c_week = 7;
                b_week = 15;
            }
            if (distance == 60 && week == 18) {
                c_week = 7;
                b_week = 14;
            }
            if (distance == 70 && week == 18) {
                c_week = 7;
                b_week = 14;
            }

            if (distance == 40 && week == 16) {
                c_week = 5;
                b_week = 13;
            }
            if (distance == 50 && week == 16) {
                c_week = 5;
                b_week = 13;
            }
            if (distance == 60 && week == 16) {
                c_week = 5;
                b_week = 12;
            }
            if (distance == 70 && week == 16) {
                c_week = 5;
                b_week = 12;
            }
        }

        if (marathon == 0) {
            marathon = 0;
            if (week == 16) {
                c_week = 5;
                b_week = 12;
            }

            if (distance == 30 && week == 14) {
                c_week = 4;
                b_week = 10;
            }
            if (distance == 40 && week == 14) {
                c_week = 4;
                b_week = 10;
            }
            if (distance == 50 && week == 14) {
                c_week = 4;
                b_week = 11;
            }
            if (distance == 60 && week == 14) {
                c_week = 4;
                b_week = 11;
            }
        }

        return { b_week, c_week }
    }


    const getRaceGoalsValues = async (goalWeek: number, distance: number, marathon: number, week: number, dateStarted: Date, isKm: boolean, monday_start: boolean, abort: AbortSignal, raceDate: any) => {
        let goalDistance;
        let goalWeeks;
        let goalDate;

        const goalsVal = await GetMarathonGoals(goalWeek, distance, marathon, week, abort)
        const result = goalsVal?.data?.result?.marathon;

        if (result?.length > 0) {
            goalDistance = distanceMarkerGoals(result[0].distance, isKm)
            goalDate = timeUntilRace(goalWeek, dateStarted, monday_start, raceDate)
            goalWeeks = Math.ceil(DaysBetween(strtotime(goalDate)) / 7)

            if (goalWeeks >= 0) {
                goalWeeks = 0;
            } else {
                goalWeeks = -goalWeeks
            }
        }
        return { goalDate, goalDistance, goalWeeks }
    }

    const getRaceDistancePace = (userInfo: any) => {
        const isKm = userInfo?.km === "Y"
        const type = userInfo?.type
        switch (type) {
            case 0:
                return isKm ? 21.1 : 13.1
            case 1:
                return isKm ? 42.2 : 26.2
            case 2:
                return isKm ? 5.0 : 3.1
            case 3:
                return isKm ? 10 : 6.2
            case 5:
                return isKm ? 8 : 5
            case 8:
                return isKm ? 16.1 : 10
            default:
                break;
        }
    }

    const getRaceDistance = (type: number) => {
        switch (type) {
            case 0:
                return 13.1
            case 1:
                return 26.2
            case 2:
                return 3.1
            case 3:
                return 6.2
            case 5:
                return 5
            case 8:
                return 10
            default:
                break;
        }
    }

    return {
        buildDay,
        // getCurrentVdot,
        analyseDay,
        getDailyActivityDisplay,
        getRaceDayDetails,
        getWeekDetails,
        getRaceDetailsValues,
        computelWeekTotalDistance,
        extractPace,
        timeUntilRace,
        getOverridePace,
        distanceMarker,
        distanceInterpret,
        convertPace,
        getWorkoutVideoDetails,
        getRaceGoalsValues,
        getRaceGoalsWeek,
        distanceMarkerGoals,
        weatherAdjust,
        getWTAValue,
        getWeatherData,
        getRaceDistancePace,
        getRaceDistance,
        getAdjustmentValue,
        handleAdjustPaceByLevel,
        convertPaceBase
    }
}

export default useBuildTrainingPlan
