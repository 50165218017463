import { postReq } from "../modules/apiConsume"
import useAIPlanPrompts from "../modules/useAIPlanPrompts"
import CryptoJS from 'crypto-js';

const useRSRunAIsuggestions = () => {

    const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

    const { defaultSytemPrompt, buildPromptSuggestedWeeklyMileage, buildPromptSuggestedGoalTime, buildUserRecommendation, 
        buildPromptSuggestedPlanStartDistance, buildPromptSuggestedDistanceWeekStart } = useAIPlanPrompts()

    const defaultPromptCall = async (prompt: any) => {

        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]

        // console.log('getting suggestions...')
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o-2024-08-06', gptTemp: '0' })
        if (response && response.status === "error") {
            alert(response.error.error.error.message)
        }

        return response?.data?.output || []
    }

    const getSuggestedWeeklyMileage = async (stageData: any) => {
        const isMaintenance = stageData["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = stageData["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'

        let latestResults: any = []
        // const weeklyRunData = stageData["1-0"] || {}
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        const prompt = buildPromptSuggestedWeeklyMileage(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)

        latestResults = { average: response?.average || '19', peak: response?.peak || '25' }
        // console.log('>>> Suggested weekly average: ', latestResults)

        return latestResults
    }

    const getSuggestedGoalTime = async (userRunData: any) => {

        let latestResults: any = []

        const prompt = buildPromptSuggestedGoalTime(userRunData)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.goalFinishTime || { seconds: '0', minutes: '0', hours: '0' }

        return latestResults
    }


    // rename to getSuggestedStartLongRunDistance
    const getSuggestedPlanStartDistance = async (stageData: any) => {

        const isMaintenance = stageData["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = stageData["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'

        let latestResults: any = []
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        // console.log('isMaintenancePrompt: ', isMaintenancePrompt)

        const prompt = buildPromptSuggestedPlanStartDistance(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_long_run

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const getSuggestedPlanStartDistanceSANDBOX = async (userDetails: any) => {

        let latestResults: any = []
        const prompt = buildPromptSuggestedPlanStartDistance(userDetails, false)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_long_run

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const getSuggestedDistanceWeekStartAIcall = async (stageData: any, peakWeeklyMileage: any) => {

        let latestResults: any = []
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        // console.log('isMaintenancePrompt: ', isMaintenancePrompt)

        const prompt = buildPromptSuggestedDistanceWeekStart(mergedStageDetails, peakWeeklyMileage)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_week_total_distance

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const checkPlanEligibility = async (userDetails: any) => {
        const isMaintenance = userDetails["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = userDetails["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'
        const mergedStageDetails = { ...userDetails["1-0"], ...userDetails["1-1"], ...userDetails["1-2"], ...userDetails["2"], ...userDetails["3"] }
        const prompt = buildUserRecommendation(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)
        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        if (isMaintenance) {
            return { ready: 'yes', 'reason': 'You are already in maintenance mode' }
        }
        else {
            const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o-2024-08-06', gptTemp: '0' })
            if (response && response.status === "error") {
                alert(response.error.error.error.message)
            }
            const latestResults = response?.data?.output || []

            return latestResults
        }
    }

    return { getSuggestedWeeklyMileage, getSuggestedGoalTime, checkPlanEligibility, getSuggestedPlanStartDistance, getSuggestedPlanStartDistanceSANDBOX, getSuggestedDistanceWeekStartAIcall }
}

export default useRSRunAIsuggestions