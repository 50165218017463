import { FC, useState} from 'react'


import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';

import RSCardCont from './RSCardCont';
import { SH2, P2 } from '../text/RSTypography'

interface RSProgramCardProps {
   onClick?: any,
   iconOnCLick?: any,
   title?: string,
   subtitle?: string,
   duration?: number,
   percent?: any,
   thumbnail?: string,
   color?: string,
   sx?: object
}

const RSProgramCard: FC<RSProgramCardProps> = ({ onClick, iconOnCLick, title, subtitle, duration, percent, thumbnail, color, sx = {} }) => {
		const [imgLoading, setImgLoading] = useState(true)
    return <RSCardCont onClick={() => onClick?.()} sx={{ border: 'none !important', maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', width: '100%', ...sx }}>
			<Box sx={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
				<Grid container className='card-slanted' xs={4} sx={{ backgroundColor: `${color}`, position: 'relative', height: '80px', zIndex: 1 }}>
					<Box sx={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<img src={thumbnail} onLoad={()=> setImgLoading(false)}  style={{ scale: '0.6', margin: 'auto', marginRight: '10%' }} />
					</Box>
				</Grid>
				<Grid container xs={8} sx={{ height: '80px', position: 'relative', overflow: 'hidden' }}>
					<Grid container xs={12} sx={{ pl: 1, pr: '24px', flexDirection: 'column', height: '80px' }} justifyContent='center' alignItems='flex-start'>
						<SH2 title>{title}</SH2>
						{subtitle && <P2 subtitle sx={{ textAlign: 'left' }}>{subtitle}</P2>}
						{duration ? <P2 subtitle sx={{ fontStyle: 'italic' }}>{Math.round(duration / 60)} min</P2> : <></>}
					</Grid>
				</Grid>
				{imgLoading && <Box className='card-slanted loading-days toRight' sx={{border: 'none !important', position: 'absolute', height: 'calc(100% + 1px)', width: 'calc(33.4% + 3px)', top: '50%', left: 0, transform: 'translate(-1px, -50%)'}} />}
				<IconButton onClick={(e: any) => iconOnCLick?.(e)} sx={{ color: '#010101', position: 'absolute', top: 0, right: 0, fontSize: '16px', p: '10px' }}>
					<i className="fa-solid fa-ellipsis-vertical"></i>
				</IconButton>
				<Box className='home-percent-cont'>
					{percent}% Complete
				</Box>
			</Box>
		</RSCardCont>
}

export default RSProgramCard
